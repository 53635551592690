import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Icon from '@ant-design/icons';
export const CatalogueItem7 = (props) => {
    return (_jsx(Icon, Object.assign({ component: () => {
            if (props.filled) {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.15625 15.8461C5.85938 14.5172 4.3125 12.0328 4.3125 9.1875C4.3125 4.94297 7.75547 1.5 12 1.5C16.2445 1.5 19.6875 4.94297 19.6875 9.1875C19.6875 12.0328 18.1406 14.5172 15.8438 15.8461V18.5625C15.8438 18.9773 15.5086 19.3125 15.0938 19.3125H8.90625C8.49141 19.3125 8.15625 18.9773 8.15625 18.5625V15.8461ZM9.1875 20.8125H14.8125C14.9156 20.8125 15 20.8969 15 21V21.75C15 22.1648 14.6648 22.5 14.25 22.5H9.75C9.33516 22.5 9 22.1648 9 21.75V21C9 20.8969 9.08437 20.8125 9.1875 20.8125Z" }) }));
            }
            else {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M15.2139 22.0714H8.7853C8.66744 22.0714 8.57101 22.1679 8.57101 22.2857V23.1429C8.57101 23.617 8.95405 24 9.42815 24H14.571C15.0451 24 15.4282 23.617 15.4282 23.1429V22.2857C15.4282 22.1679 15.3317 22.0714 15.2139 22.0714ZM11.9996 0C7.14869 0 3.21387 3.93482 3.21387 8.78571C3.21387 12.0375 4.98172 14.8768 7.60672 16.3955V19.5C7.60672 19.9741 7.98976 20.3571 8.46387 20.3571H15.5353C16.0094 20.3571 16.3924 19.9741 16.3924 19.5V16.3955C19.0174 14.8768 20.7853 12.0375 20.7853 8.78571C20.7853 3.93482 16.8505 0 11.9996 0ZM15.4255 14.7268L14.4639 15.2839V18.4286H9.5353V15.2839L8.57369 14.7268C6.46565 13.508 5.14244 11.2634 5.14244 8.78571C5.14244 4.99821 8.21208 1.92857 11.9996 1.92857C15.7871 1.92857 18.8567 4.99821 18.8567 8.78571C18.8567 11.2634 17.5335 13.508 15.4255 14.7268Z" }) }));
            }
        } }, props)));
};
