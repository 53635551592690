import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useContext, useState } from 'react';
import { API } from '@api/API';
import { Button, Collapse } from 'antd';
import { CollapseStyled, DividerPanel } from '@components/collapse/CollapseStyled';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { CourseProgress } from '@components/content/certificate_supplementary/CourseProgress';
import { billingCartCreate } from '@state/actions';
import strings from '@resources/localization';
import { formatSeconds } from '@util/formatters/Time';
import { KindNamePlural } from '@util/string/ModelNames';
import { LinkButton } from '@components/buttons/link_button/LinkButton';
import { Router } from '@Router';
import Paragraph from 'antd/lib/typography/Paragraph';
import { BoardContext } from '@screens/community/boards/media/MediaDetail';
import { IconPost } from '@components/content/posts/PostIcon';
export const EntityLessonTable = (props) => {
    var _a, _b, _c;
    const { cm_pk, b_pk, isOnboarding } = useContext(BoardContext);
    const { cm_pk: url_cm_pk } = useParams();
    const course = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]);
    const lessons = useAppSelector((state) => { var _a; return (_a = state.posts.all[b_pk]) === null || _a === void 0 ? void 0 : _a.items; });
    const [statusVisible, setStatusVisible] = useState(false);
    const [fulfillment, setFulfillment] = useState();
    const isSuscriptor = (_c = (_b = (_a = course === null || course === void 0 ? void 0 : course.item) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.board) === null || _c === void 0 ? void 0 : _c.includes('view');
    const dispatch = useAppDispatch();
    const history = useHistory();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const onClickAddToCart = (productPk) => {
        void dispatch(billingCartCreate({
            bodyParams: {
                products: [
                    {
                        publicKey: productPk,
                        quantity: 1,
                    },
                ],
            },
        }));
    };
    const suscribe = (publicKey) => {
        if (course.item.product) {
            onClickAddToCart(course.item.product.publicKey);
        }
        else {
            seeLesson(publicKey);
        }
    };
    const isLessonModel = (model) => {
        return 'publicKey' in model;
    };
    const seeLesson = (publicKey) => {
        if (isOnboarding) {
            history.push(Router.Path.community_settings_post_detail({ cm_pk: url_cm_pk, section: 'onboarding', item_pk: course.item.publicKey, p_pk: publicKey }));
        }
        else {
            void API.postDetails({
                urlParams: {
                    cm_pk,
                    b_pk: course.item.publicKey,
                    p_pk: publicKey,
                },
                extraParams: {
                    scope: 'detail',
                },
            }).then((result) => {
                if (result.data.sequence && result.data.sequence.certificate == 'needed:completion') {
                    setStatusVisible(true);
                    setFulfillment(result.data.sequence.sequence);
                }
                else {
                    history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}/${publicKey}`);
                }
            });
        }
    };
    const buttonTitle = (kind) => {
        return kind == 'quiz'
            ? strings.screens.evaluation.action
            : strings.general.play + ' ' + strings.screens.boards[course.item.kind].post.title.singular.toLowerCase();
    };
    return (_jsxs(_Fragment, { children: [statusVisible && fulfillment && (_jsx(TuringoModal, { open: statusVisible, onCancel: () => setStatusVisible(false), onOk: () => setStatusVisible(false), width: 572, footer: [
                    _jsx(FooterModal, { primary: {
                            action: () => setStatusVisible(false),
                            customText: 'OK',
                        } }, 'footer_modal'),
                ], children: _jsx(CourseProgress, { isLesson: true, progress: fulfillment, title: strings.screens.boards.course.certificate.progress.title, description: strings.screens.boards.course.certificate.progress.description }) })), _jsx(CollapseStyled, { ghost: true, accordion: true, children: lessons &&
                    Object.keys(lessons)
                        .filter((k) => !lessons[k].item.hidden)
                        .map((key, index) => {
                        var _a, _b, _c, _d, _e;
                        const post = lessons[key].item;
                        const isLastItem = index === Object.keys(lessons).length - 1;
                        if (post) {
                            const title = post.kind == 'quiz' ? post.quiz.title || post.title || strings.screens.evaluation.title : ((_a = post.lesson) === null || _a === void 0 ? void 0 : _a.title) || 'Untitled';
                            return (_jsxs(_Fragment, { children: [_jsx(Collapse.Panel, { header: _jsxs("span", { className: "ant-collapse-header-title", style: { display: 'flex', alignItems: 'center' }, children: [_jsx(IconPost, { kind: post.kind, style: { marginRight: '6px', display: 'flex' } }), " ", title] }), extra: (post === null || post === void 0 ? void 0 : post.kind) == 'lesson' && `${formatSeconds((_b = post.progress) === null || _b === void 0 ? void 0 : _b.duration, 'hh?:mm:ss')}`, children: _jsxs("div", { children: [_jsx(Paragraph, { style: { whiteSpace: 'pre-wrap', marginBottom: 16 }, children: (_c = post.lesson) === null || _c === void 0 ? void 0 : _c.description }), ((_e = (_d = course.item) === null || _d === void 0 ? void 0 : _d.course) === null || _e === void 0 ? void 0 : _e.subkind) != 'signature' || isSuscriptor ? (isLogged ? (_jsx(Button, { type: "primary", onClick: () => (isSuscriptor ? seeLesson(post.publicKey) : suscribe(post.publicKey)), children: buttonTitle(post.kind) })) : (_jsx(LinkButton, { type: "primary", href: Router.Path.login({}), children: buttonTitle(post.kind) }))) : (_jsx(_Fragment, {}))] }) }, `lessons#${index}`), !isLastItem && _jsx(DividerPanel, {}, `dividerLesson#${index}`)] }));
                        }
                        else {
                            return _jsx(_Fragment, {});
                        }
                    }) })] }));
};
