import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOne, Play } from '@icon-park/react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '@hooks/useStore';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Card, Progress } from 'antd';
import { API } from '@api/API';
import { LinkeableCard } from '@components/cards/linkeable_card/LinkeableCard';
import { EvaluationIcon } from '@components/icons/EvaluationIcon';
import strings from '@resources/localization';
import { Router } from '@Router';
import { formatSeconds } from '@util/formatters/Time';
import { Colors } from '@util/color/Colors';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { useContext, useState } from 'react';
import { LockedIcon } from '@components/icons/LockedIcon';
import { CourseProgress } from '@components/content/certificate_supplementary/CourseProgress';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { PostContext } from '@components/content/posts/Post';
const Playlist = (props) => {
    var _a, _b, _c, _d;
    const history = useHistory();
    const { cm_pk, b_pk, p_pk, isPanelAdmin } = useContext(PostContext);
    const language = useAppSelector((state) => state.ui.language);
    const { lessons, kind } = props;
    const lessonsToShow = (_a = Object.keys(lessons)) === null || _a === void 0 ? void 0 : _a.filter((k) => !lessons[k].item.hidden);
    const [progress, setProgress] = useState();
    const quizkey = lessonsToShow.find((key) => { var _a; return ((_a = lessons[key].item) === null || _a === void 0 ? void 0 : _a.kind) == 'quiz'; });
    const quizSequentialIndex = lessonsToShow.findIndex((key) => { var _a, _b, _c; return (_c = (_b = (_a = lessons[key].item) === null || _a === void 0 ? void 0 : _a.quizinfo) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.sequential; });
    const liChildren = lessonsToShow.map((key, index) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
        const item = lessons[key].item;
        const scoreObtained = (_d = (_c = (_b = (_a = lessons[quizkey]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.quiz) === null || _c === void 0 ? void 0 : _c.results) === null || _d === void 0 ? void 0 : _d.score;
        const scoreToObtain = (_h = (_g = (_f = (_e = lessons[quizkey]) === null || _e === void 0 ? void 0 : _e.item) === null || _f === void 0 ? void 0 : _f.quizinfo) === null || _g === void 0 ? void 0 : _g.score) === null || _h === void 0 ? void 0 : _h.passing;
        const nAttempts = ((_m = (_l = (_k = (_j = lessons[quizkey]) === null || _j === void 0 ? void 0 : _j.item) === null || _k === void 0 ? void 0 : _k.quiz) === null || _l === void 0 ? void 0 : _l.results) === null || _m === void 0 ? void 0 : _m.repetition) ? (_r = (_q = (_p = (_o = lessons[quizkey]) === null || _o === void 0 ? void 0 : _o.item) === null || _p === void 0 ? void 0 : _p.quiz) === null || _q === void 0 ? void 0 : _q.results) === null || _r === void 0 ? void 0 : _r.repetition : 0;
        const showResults = (_v = (_u = (_t = (_s = lessons[quizkey]) === null || _s === void 0 ? void 0 : _s.item) === null || _t === void 0 ? void 0 : _t.quiz) === null || _u === void 0 ? void 0 : _u.options) === null || _v === void 0 ? void 0 : _v.showResults;
        const isEvaluation = ((_z = (_y = (_x = (_w = lessons[quizkey]) === null || _w === void 0 ? void 0 : _w.item) === null || _x === void 0 ? void 0 : _x.quiz) === null || _y === void 0 ? void 0 : _y.scoring) === null || _z === void 0 ? void 0 : _z.passing) > 0;
        const percentFulfillment = (((_0 = item.progress) === null || _0 === void 0 ? void 0 : _0.fulfillment) / ((_1 = item.progress) === null || _1 === void 0 ? void 0 : _1.duration)) * 100;
        let successStatus = false;
        let neutralStatus = false;
        let rejectedStatus = false;
        let inprogressStatus = false;
        let isReleased = false;
        const todayDate = new Date();
        if ((item === null || item === void 0 ? void 0 : item.kind) == 'lesson') {
            successStatus = percentFulfillment >= 80;
            neutralStatus = percentFulfillment == 0;
            inprogressStatus = !successStatus && !neutralStatus;
            isReleased = item.dateRelease ? new Date(item.dateRelease) < todayDate : true;
        }
        else if ((item === null || item === void 0 ? void 0 : item.kind) == 'quiz') {
            const isApproved = ((_3 = (_2 = item.quiz) === null || _2 === void 0 ? void 0 : _2.results) === null || _3 === void 0 ? void 0 : _3.score) !== undefined && ((_5 = (_4 = item === null || item === void 0 ? void 0 : item.quiz) === null || _4 === void 0 ? void 0 : _4.results) === null || _5 === void 0 ? void 0 : _5.score) >= ((_7 = (_6 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _6 === void 0 ? void 0 : _6.score) === null || _7 === void 0 ? void 0 : _7.passing);
            successStatus = (isApproved || !isEvaluation || !showResults) && nAttempts > 0;
            neutralStatus = nAttempts == 0;
            rejectedStatus = !successStatus && !neutralStatus;
            isReleased = ((_9 = (_8 = item.quizinfo) === null || _8 === void 0 ? void 0 : _8.meta) === null || _9 === void 0 ? void 0 : _9.datefrom) ? new Date((_11 = (_10 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _10 === void 0 ? void 0 : _10.meta) === null || _11 === void 0 ? void 0 : _11.datefrom) < todayDate : true;
        }
        const isBlocked = !isReleased ||
            (!(!showResults && nAttempts > 0) &&
                quizSequentialIndex >= 0 &&
                quizSequentialIndex < index &&
                (nAttempts == 0 || scoreObtained === undefined || scoreObtained < scoreToObtain));
        const isQuiz = item.kind == 'quiz';
        const isPlaying = p_pk == item.publicKey;
        let isClickable = true;
        if (isQuiz && !isReleased) {
            isClickable = false;
        }
        const getOutlineColor = () => {
            if (isBlocked)
                return 'var(--neutral-6)';
            if (rejectedStatus)
                return 'var(--alert-error-outline)';
            if (successStatus)
                return 'var(--alert-success-outline)';
            if (inprogressStatus || isPlaying)
                return 'var(--alert-warning-outline)';
            if (neutralStatus)
                return 'var(--alert-neutral-outline)';
        };
        const getIconColor = () => {
            if (isBlocked)
                return 'var(--neutral-6)';
            if (rejectedStatus)
                return 'var(--alert-error-icon)';
            if (successStatus)
                return 'var(--alert-success-icon)';
            if (inprogressStatus || isPlaying)
                return 'var(--alert-warning-icon)';
            if (neutralStatus)
                return 'var(--alert-neutral-icon)';
        };
        const getColoredTextColor = () => {
            if (isBlocked)
                return 'var(--neutral-6)';
            if (rejectedStatus)
                return 'var(--alert-error-text)';
            if (successStatus)
                return 'var(--alert-success-text)';
            if (inprogressStatus || isPlaying)
                return 'var(--alert-warning-text)';
            if (neutralStatus)
                return 'var(--alert-neutral-text)';
        };
        const getTextColor = () => {
            if (isBlocked)
                return 'var(--neutral-6)';
            return 'var(--text)';
        };
        const getBackgroundColor = () => {
            if (isBlocked)
                return 'var(--neutral-3)';
            if (rejectedStatus)
                return 'var(--alert-error-background)';
            if (successStatus)
                return 'var(--alert-success-background)';
            if (inprogressStatus || isPlaying)
                return 'var(--alert-warning-background)';
            if (neutralStatus)
                return 'var(--alert-neutral-background)';
        };
        const onClick = () => {
            if (!isClickable)
                return;
            if (isBlocked) {
                void API.postDetails({
                    urlParams: {
                        cm_pk,
                        b_pk,
                        p_pk: item.publicKey,
                    },
                    extraParams: {
                        scope: 'detail',
                    },
                }).then((result) => {
                    if (result.data.sequence && result.data.sequence.certificate == 'needed:completion') {
                        const sequence = result.data.sequence.sequence;
                        setProgress(sequence);
                    }
                    else {
                        history.push(Router.Path[`${kind}_post_details`]({ cm_pk, b_pk, p_pk: item.publicKey }));
                    }
                });
            }
            else {
                if (isPanelAdmin) {
                    history.push(Router.Path.community_settings_post_detail({ cm_pk, section: 'onboarding', item_pk: b_pk, p_pk: item.publicKey }));
                }
                else {
                    history.push(Router.Path[`${kind}_post_details`]({ cm_pk, b_pk, p_pk: item.publicKey }));
                }
            }
        };
        return (_jsx("div", { style: { marginBottom: 16 }, children: _jsx(LinkeableCard, { linkStyle: { height: 'inherit' }, onClick: onClick, style: { height: 'inherit', borderRadius: 4, cursor: 'pointer' }, bordered: false, bodyStyle: {
                    color: isReleased ? 'inherit' : 'white',
                    padding: '8px 16px',
                    backgroundColor: getBackgroundColor(),
                    borderRadius: 4,
                    border: isPlaying ? `2px solid ${getOutlineColor()}` : '',
                }, children: _jsxs("div", { style: { display: 'grid', gridTemplateColumns: '56px 1fr', minHeight: 96 }, children: [_jsxs("div", { style: {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, children: [isQuiz && !isBlocked && (_jsx(EvaluationIcon, { style: {
                                        height: 48,
                                        width: 48,
                                        color: getIconColor(),
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    } })), isBlocked && (_jsx(LockedIcon, { style: {
                                        height: 48,
                                        width: 48,
                                        color: getIconColor(),
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    } })), !isQuiz && neutralStatus && !isBlocked && _jsx(Play, { theme: "outline", size: "56", fill: getIconColor(), strokeWidth: 2 }), !isQuiz && successStatus && !isBlocked && _jsx(CheckOne, { theme: "outline", size: "56", fill: getIconColor(), strokeWidth: 2 }), !isQuiz && inprogressStatus && !isBlocked && isPlaying && (_jsx(Progress, { type: "circle", trailColor: Colors('NEUTRAL_1'), strokeColor: getIconColor(), percent: percentFulfillment, width: 44, showInfo: false })), !isQuiz && inprogressStatus && !isBlocked && !isPlaying && (_jsx(Progress, { type: "circle", strokeColor: getIconColor(), percent: percentFulfillment, width: 44, trailColor: Colors('NEUTRAL_1'), format: (percent) => {
                                        return (_jsx("svg", { width: "48", height: "48", viewBox: "0 0 52 46", fill: "none", children: _jsx("path", { d: "M20 24V17.0718L26 20.5359L32 24L26 27.4641L20 30.9282V24Z", fill: "none", stroke: getIconColor(), strokeWidth: "2", strokeLinejoin: "round" }) }));
                                    } }))] }), _jsxs("div", { style: {
                                marginLeft: 16,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: !isQuiz && isReleased ? 'space-between' : 'center',
                            }, children: [_jsxs("div", { children: [_jsx(Paragraph, { style: { fontSize: isMobile ? 14 : 12, marginBottom: 4, color: getTextColor() }, children: !isQuiz
                                                ? item.contentSequence >= 0 && `${(_14 = (_13 = (_12 = strings.screens.boards[kind]) === null || _12 === void 0 ? void 0 : _12.post) === null || _13 === void 0 ? void 0 : _13.title) === null || _14 === void 0 ? void 0 : _14.singular} ${index + 1}`
                                                : strings.screens.evaluation.title }), _jsx(Paragraph, { strong: true, style: { marginBottom: 8, fontSize: isMobile ? 16 : 14, color: getTextColor() }, ellipsis: { rows: 2 }, children: !isQuiz ? (_15 = item.lesson) === null || _15 === void 0 ? void 0 : _15.title : item.quiz.title })] }), !isQuiz && isReleased && (_jsx("div", { children: _jsx(Paragraph, { style: Object.assign(Object.assign({ fontSize: isMobile ? 14 : 12, marginBottom: 4 }, (isPlaying && { fontWeight: 500, color: getColoredTextColor() })), (isBlocked && { color: getTextColor() })), children: isPlaying ? strings.general.inProgress : formatSeconds((_16 = item.progress) === null || _16 === void 0 ? void 0 : _16.duration, 'hh?:mm:ss') }) })), isQuiz && (_jsx("div", { children: _jsx(Paragraph, { style: Object.assign({ fontSize: isMobile ? 14 : 12, marginBottom: 4, color: getColoredTextColor() }, (isPlaying && { fontWeight: 500 })), children: isReleased
                                            ? successStatus && isPlaying
                                                ? !isEvaluation || !showResults
                                                    ? strings.general.completed
                                                    : strings.general.approved
                                                : rejectedStatus && isPlaying
                                                    ? strings.general.failed
                                                    : isPlaying
                                                        ? strings.general.inProgress
                                                        : ''
                                            : `${strings.general.availableOn} ${dayjs((_18 = (_17 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _17 === void 0 ? void 0 : _17.meta) === null || _18 === void 0 ? void 0 : _18.datefrom)
                                                .locale(language)
                                                .format(strings.general.dateTimeFormat)}` }) }))] })] }) }) }, `lesson-card-${item.publicKey}`));
    });
    return (_jsxs(Card, { bordered: false, title: (_d = (_c = (_b = strings.screens.boards[kind]) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.plural, children: [progress && (_jsx(TuringoModal, { open: !!progress, onCancel: () => setProgress(undefined), onOk: () => setProgress(undefined), width: 572, footer: [
                    _jsx(FooterModal, { primary: {
                            action: () => setProgress(undefined),
                            customText: 'OK',
                        } }, 'footer_modal'),
                ], children: _jsx(CourseProgress, { isLesson: true, progress: progress, title: strings.screens.boards.course.certificate.progress.title, description: strings.screens.boards.course.certificate.progress.description }) })), _jsx("div", { className: 'scrollStyle', style: { height: 600, overflow: 'auto', paddingRight: 10 }, children: liChildren })] }));
};
export { Playlist };
