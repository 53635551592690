import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Icon from '@ant-design/icons';
export const CatalogueItem15 = (props) => {
    return (_jsx(Icon, Object.assign({ component: () => {
            if (props.filled) {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.5 6V5.5C7.5 3.01472 9.51471 1 12 1C14.4853 1 16.5 3.01472 16.5 5.5V6H19C20.1046 6 21 6.89543 21 8V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V8C3 6.89543 3.89543 6 5 6H7.5ZM9.5 5.5C9.5 4.11929 10.6193 3 12 3C13.3807 3 14.5 4.11929 14.5 5.5V6H9.5V5.5Z" }) }));
            }
            else {
                return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 3C10.6193 3 9.5 4.11929 9.5 5.5V6H14.5V5.5C14.5 4.11929 13.3807 3 12 3ZM16.5 6V5.5C16.5 3.01472 14.4853 1 12 1C9.51471 1 7.5 3.01472 7.5 5.5V6H5C3.89543 6 3 6.89543 3 8V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V8C21 6.89543 20.1046 6 19 6H16.5ZM14.5 8V9C14.5 9.55228 14.9477 10 15.5 10C16.0523 10 16.5 9.55228 16.5 9V8H19V21H5V8H7.5V9C7.5 9.55228 7.94772 10 8.5 10C9.05228 10 9.5 9.55228 9.5 9V8H14.5Z" }) }));
            }
        } }, props)));
};
