import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Icon from '@ant-design/icons';
export const CatalogueItem16 = (props) => {
    return (_jsx(Icon, Object.assign({ component: () => {
            if (props.filled) {
                return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M9.50024 6C8.94796 6 8.50024 6.44772 8.50024 7C8.50024 7.55228 8.94796 8 9.50024 8H14.5002C15.0525 8 15.5002 7.55228 15.5002 7C15.5002 6.44772 15.0525 6 14.5002 6H9.50024Z" }), _jsx("path", { d: "M7.00024 11C7.00024 10.4477 7.44796 10 8.00024 10H16.0002C16.5525 10 17.0002 10.4477 17.0002 11C17.0002 11.5523 16.5525 12 16.0002 12H8.00024C7.44796 12 7.00024 11.5523 7.00024 11Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.5002 2C4.02653 2 3.61783 2.33233 3.52122 2.79605L1.02506 14.7776C1.01746 14.8111 1.01154 14.8452 1.00739 14.8798C1.00152 14.9279 0.999196 14.9758 1.00024 15.0231V21.5C1.00024 22.0523 1.44796 22.5 2.00024 22.5H22.0002C22.5525 22.5 23.0002 22.0523 23.0002 21.5V15.0187C23.0009 14.9845 22.9997 14.95 22.9967 14.9154C22.9927 14.867 22.9852 14.8197 22.9745 14.7735L20.4792 2.79605C20.3826 2.33233 19.9739 2 19.5002 2H4.5002ZM20.7704 14L18.6871 4H5.31334L3.23001 14H7.45479C7.89537 14 8.28404 14.2883 8.41181 14.71L9.10575 17H14.8947L15.5887 14.71C15.7164 14.2883 16.1051 14 16.5457 14H20.7704Z" })] }));
            }
            else {
                return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: 'currentColor', xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M8.5002 7C8.5002 6.44772 8.94792 6 9.5002 6H14.5002C15.0525 6 15.5002 6.44772 15.5002 7C15.5002 7.55228 15.0525 8 14.5002 8H9.5002C8.94792 8 8.5002 7.55228 8.5002 7Z" }), _jsx("path", { d: "M8.0002 10C7.44792 10 7.0002 10.4477 7.0002 11C7.0002 11.5523 7.44792 12 8.0002 12H16.0002C16.5525 12 17.0002 11.5523 17.0002 11C17.0002 10.4477 16.5525 10 16.0002 10H8.0002Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.5002 2C4.02653 2 3.61783 2.33233 3.52122 2.79605L1.02551 14.7755C1.01637 14.8153 1.00962 14.856 1.00539 14.8975C1.00107 14.9389 0.999379 14.9802 1.0002 15.021V21.5C1.0002 22.0523 1.44792 22.5 2.0002 22.5H22.0002C22.5525 22.5 23.0002 22.0523 23.0002 21.5V15.021C23.001 14.9802 22.9993 14.9389 22.995 14.8975C22.9908 14.856 22.984 14.8153 22.9749 14.7755L20.4792 2.79605C20.3826 2.33233 19.9739 2 19.5002 2H4.5002ZM20.7704 14L18.6871 4H5.31334L3.23001 14H7.45475C7.89533 14 8.284 14.2883 8.41177 14.71L9.10571 17H14.8947L15.5886 14.71C15.7164 14.2883 16.1051 14 16.5457 14H20.7704ZM3.0002 20.5V16H6.71287L7.40681 18.29C7.53458 18.7117 7.92326 19 8.36384 19H15.6366C16.0771 19 16.4658 18.7117 16.5936 18.29L17.2875 16H21.0002V20.5H3.0002Z" })] }));
            }
        } }, props)));
};
