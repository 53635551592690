import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { boardList } from '@state/actions';
import { useParams } from 'react-router';
import { Alert, Card, Divider, Input, Skeleton } from 'antd';
import strings from '@resources/localization';
import { ImageSkeleton } from '@components/skeletons/components/ImageSkeleton/ImageSkeleton';
import InfiniteCards from '@components/infinite/InfiniteCards';
import { PushpinOutlined } from '@ant-design/icons';
import { tableColor } from '@state/reducers/UserInterface/UserInterfaceReducer';
import useBreakpoints from '@hooks/useBreakpoints';
import { debounce } from 'lodash';
import { BoardCard } from '@components/cards/board_card/BoardCard';
const EntitiesList = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { kind, following, searchBar } = props;
    const color = useAppSelector((state) => state.ui.color);
    const { cm_pk } = useParams();
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const fetchBoards = (first) => {
        void dispatch(boardList({
            urlParams: { cm_pk },
            searchParams: Object.assign(Object.assign({ kind: kind }, (searchTerm.length > 0 && { term: searchTerm })), (following && { owned: 'true' })),
            headerParams: {
                page: first ? 'first' : boardsListState === null || boardsListState === void 0 ? void 0 : boardsListState.pagination,
                pageCount: 10,
            },
        }));
    };
    const debounced = debounce(fetchBoards, 1100, {
        leading: true,
        trailing: false,
    });
    useEffect(() => {
        debounced(true);
    }, [searchTerm, kind, following]);
    const [timer, setTimer] = useState();
    const onChange = (e) => {
        if (timer) {
            clearTimeout(timer);
        }
        const timerRef = setTimeout(() => setSearchTerm(e.target.defaultValue.trim()), 500);
        setTimer(timerRef);
    };
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const boards = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) === null || _b === void 0 ? void 0 : _b.all[kind]) === null || _c === void 0 ? void 0 : _c.items; });
    const boardsListState = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) === null || _b === void 0 ? void 0 : _b.all[kind]) === null || _c === void 0 ? void 0 : _c.listState; });
    const breakpoints = useBreakpoints();
    const gridTemplate = breakpoints.xxs || breakpoints.xs || breakpoints.sm ? '1fr' : breakpoints.mdl || breakpoints.md ? '1fr 1fr' : '1fr 1fr 1fr';
    return (_jsxs(_Fragment, { children: [searchBar && (_jsxs("div", { children: [_jsx(Input.Search, { size: "large", onChange: onChange, style: { maxWidth: 450 }, placeholder: strings.formatString((_a = strings.screens.boards[kind]) === null || _a === void 0 ? void 0 : _a.list.search.placeholder, ((_f = (_e = (_d = (_c = (_b = community === null || community === void 0 ? void 0 : community.item) === null || _b === void 0 ? void 0 : _b.ui) === null || _c === void 0 ? void 0 : _c.rename) === null || _d === void 0 ? void 0 : _d[kind]) === null || _e === void 0 ? void 0 : _e.plural) === null || _f === void 0 ? void 0 : _f.toLowerCase()) || strings.general[kind].plural.toLowerCase()) }), _jsx(Divider, {})] })), _jsxs(InfiniteCards, { dataLength: !boardsListState ? undefined : Object.keys(boards).length, loading: boardsListState ? boardsListState.loading : true, next: () => {
                    fetchBoards(false);
                }, style: { display: 'grid', gridTemplateColumns: gridTemplate, gap: breakpoints.isMobile ? 16 : 24 }, hasMore: ((boardsListState === null || boardsListState === void 0 ? void 0 : boardsListState.pagination) && (boardsListState === null || boardsListState === void 0 ? void 0 : boardsListState.pagination) != 'first') || (boardsListState === null || boardsListState === void 0 ? void 0 : boardsListState.loading), loader: _jsx(Card, { style: { maxHeight: 630 }, cover: _jsx(ImageSkeleton, {}), children: _jsx(Skeleton, { active: true, title: true, paragraph: { rows: 4 } }) }), empty: searchTerm.length == 0 && _jsx(_Fragment, {}), children: [boards && Object.keys(boards).length == 0 && !(boardsListState === null || boardsListState === void 0 ? void 0 : boardsListState.loading) && searchTerm.length == 0 && (_jsx(Alert, { style: { gridColumnStart: 1, gridColumnEnd: 4 }, message: strings.formatString((_g = strings.screens.boards[kind]) === null || _g === void 0 ? void 0 : _g.list.suscriptions.empty, ((_m = (_l = (_k = (_j = (_h = community === null || community === void 0 ? void 0 : community.item) === null || _h === void 0 ? void 0 : _h.ui) === null || _j === void 0 ? void 0 : _j.rename) === null || _k === void 0 ? void 0 : _k[kind]) === null || _l === void 0 ? void 0 : _l.plural) === null || _m === void 0 ? void 0 : _m.toLowerCase()) || strings.general[kind].plural.toLowerCase()), icon: _jsx(PushpinOutlined, { style: { color: tableColor[color] } }) })), boards &&
                        ((_p = (_o = Object.keys(boards)).map) === null || _p === void 0 ? void 0 : _p.call(_o, (pk) => {
                            var _a, _b, _c, _d, _e, _f;
                            const showCertificate = ((_b = (_a = boards[pk].item) === null || _a === void 0 ? void 0 : _a.merits) === null || _b === void 0 ? void 0 : _b.find((m) => m.merit.kind == 'certificate')) && !!((_f = (_e = (_d = (_c = boards[pk]) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.course) === null || _e === void 0 ? void 0 : _e.assign) === null || _f === void 0 ? void 0 : _f.cert);
                            return _jsx(BoardCard, { from: "course.list", withProgress: following, item: boards[pk].item, showCertificate: !!showCertificate }, pk);
                        }))] })] }));
};
export { EntitiesList };
