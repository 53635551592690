const strings = {
    notifications: {
        title: 'Notifications',
        empty: 'No new notifications',
        showMore: 'Show all notifications',
        new: 'New',
        previous: 'Previous',
        kind: {
            'sys.convert.finished': {
                default: 'The video of',
                one: 'lesson is now available',
            },
            'usr.community.invited': {
                default: 'invited you to',
            },
            'usr.community.join': {
                default: 'joined the community',
            },
            'usr.community.post': {
                default: 'posted in the community',
            },
            'admin.community.post': {
                default: 'posted in',
            },
            'usr.post.like': {
                default: 'liked your post',
                one: 'and one more person liked your post',
                many: 'and {0} people liked your post',
            },
            'usr.comment.like': {
                default: 'liked your comment',
                one: 'and one more person liked your comment',
                many: 'and {0} people liked your post',
            },
            'usr.post.comment': {
                default: 'commented on your post',
                one: 'and one more person commented on your post',
                many: 'and {0} people commented on your post',
            },
            'usr.post.mention': {
                default: 'mentioned you in a post',
                one: 'and one more person mentioned you in a post',
                many: 'and {0} people mentioned you in a post',
            },
            'usr.comment.mention': {
                default: 'mentioned you in a comment',
                one: 'and one more person mentioned you in a comment',
                many: 'and {0} people mentioned you in a comment',
            },
            'usr.comment.reply': {
                default: 'replied to your comment',
                one: 'and one more person replied to your comment',
                many: 'and {0} people replied to your comment',
            },
            'usr.poll.vote': {
                default: 'voted in your survey',
                one: 'and one more person voted in your survey',
                many: 'and {0} people voted in your survey',
            },
            'usr.classroom.complete': {
                default: 'Congratulations! You’ve received a certificate for completing the course',
            },
            'sys.poll.expired': {
                default: 'Your survey has ended, check the results',
            },
            'sys.classroom.remind': {
                default: "You haven't finished the course {0} yet. Complete it and get your certificate!",
            },
            'sys.account.validation': {
                default: '',
            },
            'mkt.classroom.launch': {
                default: 'New course launch {0}. Get your certificate!',
            },
            'mkt.classroom.discount': {
                default: '{0}% off in course',
            },
            'usr.post.report': {
                default: 'A report of a post has been sent',
            },
            'sys.poke': {
                default: 'System notification',
            },
            'com.team.added': {
                default: 'You have been added to the team',
            },
            'com.community.invited': {
                default: 'You have been invited to the community',
            },
            'com.community.post': {
                default: 'has made a post in',
            },
            'com.admin.added': {
                default: 'You have been named Admin of the community',
            },
            'com.course.rate': {
                default: 'A rating has been created for the course',
            },
            'com.course.quiz.approved': {
                default: 'Congratulations! You have passed the course evaluation',
            },
            'com.course.quiz.approved.with.result.and.criteria': {
                default: 'Congratulations! You have passed the course evaluation',
            },
            'com.course.quiz.approved.no.criteria': {
                default: 'Congratulations! You finish the course',
            },
            'com.course.merit': {
                default: 'Congratulations! You have obtained a certificate for completing the course',
            },
            'com.course.comment': {
                default: 'A comment has been created in the course',
            },
            'com.course.remind': {
                default: 'You haven’t finished the course {0}. Keep going and complete your course!',
            },
            'com.catalog.comment': {
                default: 'A comment has been created on the item',
            },
            'com.post.pinned.mandatory': {
                default: 'has highlighted a post.',
            },
            'com.post.pinned.normal': {
                default: 'has highlighted a post.',
            },
            'com.event.added': {
                default: 'You have been invited to the event',
            },
        },
    },
    reports: {
        kinds: {
            fake: 'False information',
            sales: 'Unauthorized Sales',
            inappropiate: 'Inappropriate Language',
            spam: 'Spam',
            abuse: 'Infringes community rules',
            other: 'Other',
        },
        initial: {
            title: 'Report',
            description: {
                rate: 'Select the reason you are reporting this assessment:',
                comment: 'Select the reason you are reporting this comment:',
                post: 'Select the reason you are reporting this post:',
                room: 'Select the reason you are reporting this conversation:',
                message: 'Select the reason you are reporting this message:',
            },
        },
        selected: {
            title: 'You have selected option',
            description: 'This information will be sent to our team to be analyzed as soon as possible.',
            continue: 'Would you like to continue with the report?',
            other: {
                label: 'What are you reporting?',
                prompt: 'Help us to understand the problem in a sentence',
            },
            other_details: {
                label: 'Give us more details',
                placeholder: "e.g.: Another person from the community impersonates me, made an account with my first and last name and I don't know what to do.",
            },
        },
        finish: {
            success: {
                title: 'Thank you! We have received your report',
                description: 'We build this community together, so your help is essential to create a safe and reliable environment.',
            },
            error: {
                title: 'Not found',
                description: 'We had a problem submitting your report. Please try again.',
            },
        },
    },
    rates: {
        title: 'Ratings',
        createAction: 'Create rating',
        more: 'See more',
        form: {
            rate: 'How many stars would you rate it with?',
            content: {
                label: 'Tell other people why  ',
                placeholder: 'e.g.: I liked it because I was able to do it at my own pace and I was able to apply the content to my business. Highly recommended! ',
            },
            edit_title: 'Edit your rating',
        },
        delete: {
            title: 'Do you want to delete this rating?',
            description: 'Once deleted, your rating cannot be recovered.',
        },
    },
    comments: {
        title: 'Comments',
        empty: 'Be part of the Turingo community and interact with other people by commenting on this content.',
        more: 'See more comments',
        form: {
            placeholder: 'Share with others what you thought of this content.',
            help: 'Do you need help?',
            contactUs: 'Write us',
            edit_title: 'Edit your comment',
        },
        delete: {
            title: 'Do you want to delete this comment?',
            description: 'Once deleted, your message cannot be recovered.',
        },
    },
    general: {
        board_availables: 'Availables',
        board_inprogress: 'In progress',
        uploading: 'Uploading',
        videoProcessing: 'The video is being processed. You can create the lesson and receive a notification when it is available',
        processing: 'Processing',
        clipboard: 'Copy to clipboard',
        exit: 'Exit',
        discussion: {
            singular: 'Space',
            plural: 'Spaces',
        },
        course: {
            singular: 'Course',
            plural: 'Courses',
        },
        organization: {
            singular: 'Organization',
            plural: 'Organizations',
        },
        user: {
            singular: 'Person',
            plural: 'People',
        },
        forum: {
            singular: 'Talk',
            plural: 'Talks',
        },
        community: {
            singular: 'Community',
            plural: 'Communities',
        },
        story: {
            singular: 'Story',
            plural: 'Stories',
        },
        catalog: {
            singular: 'Catalogue',
            plural: 'Catalogues',
        },
        lesson: {
            singular: 'Lesson',
            plural: 'Lessons',
        },
        post: {
            singular: 'Post',
            plural: 'Posts',
        },
        session: {
            singular: 'Session',
            plural: 'Sessions',
        },
        material: {
            singular: 'Material',
            plural: 'Materials',
        },
        availableMaterial: 'Material available',
        attendance: {
            singular: 'Going',
            plural: 'Going',
        },
        time: {
            hours: {
                zero: '0 hours',
                one: '1 hour',
                nary: '{0} hours',
            },
            minutes: {
                zero: '0 minutes',
                one: '1 minute',
                nary: '{0} minutes',
            },
            seconds: {
                zero: '0 seconds',
                one: '1 second',
                nary: '{0} seconds',
            },
            hoursCompact: {
                zero: '0 hrs',
                one: '1 hr',
                nary: '{0} hrs',
            },
            minutesCompact: {
                zero: '0 min',
                one: '1 min',
                nary: '{0} min',
            },
            secondsCompact: {
                zero: '0 s',
                one: '1 s',
                nary: '{0} s',
            },
            hhmm: '{0} and {1}',
            times: '{0} times',
        },
        languages: {
            english: 'English',
            spanish: 'Spanish',
        },
        audio: 'Audio',
        subtitles: 'Subtitles',
        duration: 'Duration',
        downloadableContent: 'Additional material',
        download: 'Download',
        share: 'Share',
        watchFree: 'Watch free',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthYear: '{0} {1}',
        createdBy: 'Created by',
        sponsor: 'Sponsored by',
        contributor: 'In collaboration with',
        promoter: 'Promoted by',
        supporter: 'Supported by',
        watching: 'Watching now',
        play: 'Play',
        next: 'Next',
        edit: 'Edit',
        report: 'Report',
        delete: 'Delete',
        loading: 'Loading',
        seeMore: 'See more {0}',
        send: 'Send',
        save: 'Save',
        add: 'Add',
        cancel: 'Cancel',
        remove: 'Remove',
        back: 'Back',
        return: 'Back',
        example: {
            plural: 'Examples',
            singular: 'Example',
        },
        continue: 'Continue',
        finish: 'Finish',
        affirmation: 'Yes',
        negative: 'No',
        include: 'Included',
        notInclude: 'Not included',
        date: 'MMMM D, YYYY',
        title: 'Title',
        language: 'Language',
        localization: 'Localización',
        fullName: 'First and last name',
        yes: 'yes',
        no: 'no',
        availableOn: 'Available:',
        releasesOn: 'Releases on:',
        placeholder: {
            courseTitle: 'Magnificent Course',
            person: 'John Appleseed',
        },
        image: {
            plural: 'images',
            singular: 'image',
        },
        certificate: {
            title: {
                plural: 'Certificates',
                singular: 'Certificate',
            },
            included: 'Included',
            notIncluded: 'Not included',
        },
        email_option: 'Or with your email',
        create: 'Create',
        buy: 'Buy',
        more: 'More',
        comments: {
            action: 'Comment',
            zero: '0 comments',
            one: '1 comment',
            nary: '{0} comments',
        },
        replies_previuos: {
            zero: '0 previous replies',
            one: '1 previous reply',
            nary: '{0} previous replies',
        },
        replies: {
            action: 'Reply',
            zero: '0 replies',
            one: '1 reply',
            nary: '{0} replies',
        },
        votes: {
            title: 'Votaciones',
            zero: '0 votes',
            one: '1 vote',
            nary: '{0} votes',
            finish: 'Finished',
            time: {
                hours: {
                    zero: '0 hours left',
                    one: '1 hour left',
                    nary: '{0} hours left',
                },
                minutes: {
                    zero: '0 minutes left',
                    one: '1 minute left',
                    nary: '{0} minutes left',
                },
                days: {
                    zero: '0 days left',
                    one: '1 day left',
                    nary: '{0} days left',
                },
            },
        },
        like: 'Like',
        topost: 'Post',
        seeMoreText: 'Read more',
        seeLessText: 'Read less',
        newRelease: 'New release',
        dateFormat: 'MMMM DD',
        dateTimeFormat: 'DD [of] MMM[.], hh[:]mm a',
        detail: 'Detail',
        description: 'Description',
        about: 'About',
        mentors: 'Mentors',
        participants: 'Participants',
        audience: 'Ideal for',
        abilities: 'At the end',
        continents: {
            na: 'North America',
            sa: 'South America',
            eu: 'Europe',
            af: 'Africa',
            oc: 'Oceania',
            as: 'Asia',
            an: 'Antarctida',
        },
        pay: 'Pay',
        read_more: 'Read more',
        visibility: 'Visibility',
        created: 'Created',
        public: 'Public',
        private: 'Private',
        create_your_community: 'Create your community',
        turingo_community: 'Turingo Community',
        noTags: 'No tags',
        noDescription: 'No description',
        purshased: 'Purshased',
        goToProfile: 'Go to profile',
        filter: 'Filter',
        sortBy: 'Sort by',
        clean: 'Clean',
        country: 'Country',
        services: 'Services',
        industries: 'Industries',
        interests: 'Interests',
        noResults: 'No results found',
        mute: 'Mute',
        unmute: 'Unmute',
        accept: 'Accept',
        searchMember: 'Search member',
        searchInvitation: 'Search invitation',
        onlyNamesAndNumbers: 'Can only contain letters and numbers.',
        moreDetails: 'More details',
        payment: 'Pay',
        safebuy: 'Safe purchase',
        httpsinfo: 'This site uses HTTPS connection, protecting the transfer of your data.',
        reorderModal: {
            title: 'Reorder {0}',
            description: 'Change the order of the {0} by dragging and dropping them.',
            action: 'Reorder',
            success: 'The {0} have been reordered',
        },
        peoples: 'Peoples',
        select: 'Select',
        all: 'All',
        selected: 'Selected',
        notSelected: 'Not selected',
        selectStart: 'Select start',
        selectEnd: 'Select end',
        addRange: 'Add a range',
        member: 'Member',
        admin: 'Administrator',
        userKind: 'User kind',
        search: 'Search',
        filters: 'Filters',
        showResults: 'Show results',
        nResults: '{0} results',
        orderBy: 'order by',
        suggested: 'Suggested',
        copyLink: 'Copy link',
        fieldBlank: 'This field cannot be blank',
        phoneNotValid: 'Phone number is not valid',
        publish: 'Publish',
        completed: 'Completed',
        approved: 'Approved',
        failed: "Failed",
        inProgress: "In progress"
    },
    navigation: {
        sidebar: {
            home: 'Home',
            courses: 'Courses',
            forums: 'Talks',
            stories: 'Stories',
            stories_short: 'Stories',
            community: 'Community',
            spaces: 'Spaces',
            events: 'Events',
            directory: 'Directory',
            admin: 'Admin Panel',
            highlights: 'Highlights',
            support: 'Support',
        },
        usermenu: {
            title: 'My account',
            options: {
                profile: 'My profile',
                settings: 'Settings',
                help: 'Help',
                contact: 'Contact Us',
                terms: 'Terms and conditions',
                privacy: 'Privacy policy',
                blog: 'Blog',
                aboutus: 'About Turingo',
                logout: 'Logout',
                faq: 'Frequently asked questions',
            },
        },
    },
    modals: {
        mask: {
            title: 'Change interaction',
            description: 'Select which name you want to interact with in the community.',
        },
        share: {
            title: 'Share',
            email: {
                subject: 'Hello! I saw this and I think you might be interested💚',
                body: 'Hello! I hope you’re doing very well today 😊\n I found this on turingo.com and I think it can help you. Turingo is a platform that has courses specially created for entrepreneurs and SME leaders. \nLet me know if you like it!',
            },
            description: 'Or copy this link',
            action: 'Copy',
            message: 'Copied',
        },
        invitation: {
            errors: {
                expired: {
                    title: 'The invitation has expired',
                    description: 'It is not possible to access the community. You need to be re-invited in order to join.',
                },
                accepted: {
                    title: 'The invitation has already been accepted',
                    description: 'It is not possible to access the community. Try logging in with the email to which the invitation was sent.',
                },
                rejected: {
                    title: 'The invitation has already been rejected',
                    description: 'It is not possible to access the community. You need to be re-invited in order to join.',
                },
                email: {
                    title: 'The email does not match the invitation email',
                    description: 'It is not possible to access the community. Try logging in with the email to which the invitation was sent.',
                },
                general: {
                    title: 'An error occurred',
                    description: 'The invitation could not be accepted. Please try again.',
                },
                'application.community.invitation.member': {
                    title: 'An error occurred',
                    description: 'It is not possible to accept this invitation because you are already part of the community. Log out and try again.',
                },
            },
        },
        welcome: {
            title: '¡{0}, we welcome you to the community!',
            description: 'Everything is ready for you to start. Introduce yourself to the community and share your first post!',
            later: 'Later',
            post: 'Say hello to the community',
        },
        whatsapp: {
            title: "Don't miss out on featured posts and receive notifications directly to your WhatsApp!",
            description: "By adding your phone number to your profile, you'll receive notifications only for featured posts directly to your WhatsApp. If you wish to deactivate it, you can do so in your account's notification settings.",
            toProfile: 'Go to profile',
        },
    },
    screens: {
        exit: {
            title: 'Your account has been deleted',
            description: 'Thank you for being part of the community. If you wish to return, you need to be invited again to be able to enter.',
        },
        onboarding: {
            step_one: {
                title: 'Complete your profile in 3 steps!',
            },
            step_two: {
                description: 'Enter a contact email to share with the community, either the same as registration or a different one.',
            },
            step_three: {
                title: "You're just 1 step away!",
                description: 'Receive WhatsApp notifications only for featured posts. Edit the settings in your account to change it.',
            },
        },
        markasread: {
            title: 'Highlight',
            banner: {
                plural: 'You have {0} unread highlighted posts.',
                singular: 'You have 1 unread highlighted post.',
                action: 'Go to highlights',
            },
            details: {
                title: 'Post details',
                description: 'Analyze in detail the featured post and the medium through which it was accessed.',
                medium: {
                    community: 'Community',
                    whatsapp: 'Via WhatsApp',
                    email: 'Via Email',
                },
                readers: {
                    title: 'Readers',
                    plural: '{0} readers',
                    singular: '1 reader',
                },
                viewers: {
                    title: 'Views',
                    plural: '{0} views',
                    singular: '1 view',
                },
            },
            actions: {
                highlight: 'Mark as highlighted',
                unhighlight: 'Remove highlighted',
                unread: 'Mark as read',
                read: 'Read',
            },
            pin_modal: {
                title: 'Mark as highlighted',
                description: 'Highlight the post to increase its visibility and add it to the Highlights section of your community.',
                email: {
                    title: 'Send email',
                    description: 'Notify people in the selected space via email, regardless of their notification settings.',
                },
                whatsapp: {
                    title: 'Send Message via WhatsApp',
                    description: 'Notify people in the selected space via WhatsApp, according to their notification settings.',
                },
                read: {
                    title: 'Mark as read',
                    description: 'Add a confirmation so that people in the selected space can mark the message as read. Otherwise, it will only be highlighted.',
                },
            },
            unpin_modal: {
                title: 'Do you want to remove the highlighted status of this post?',
                description: 'Once removed, it will not appear in the highlights section of your community.',
            },
        },
        chat: {
            showAll: 'Show all messages',
            browserTitle: 'Messages',
            browserDescription: 'Community messages',
            title: 'Messages',
            empty: 'You have no new messages',
            search: 'Search members',
            notification: {
                options: {
                    markAsRead: 'Mark as read',
                },
            },
            room: {
                options: {
                    profile: 'Go to profile',
                    markAsSilent: 'Mute conversation',
                    markAsUnsilent: 'Unmute conversation',
                    report: 'Report conversation',
                },
                create: {
                    title: 'New message',
                    description: 'Send messages and connect with other people in your community',
                },
            },
            messages: {
                urlDisclaimer: 'This external link may contain unsafe information. Make sure the person sending it to you is trustworthy.',
                options: {
                    delete: {
                        title: 'Delete Message',
                        confirm: 'Do you want to delete this message?',
                        warning: 'Once deleted, it will disappear for both parties and cannot be recovered.',
                    },
                    report: 'Report message',
                },
                placeholder: 'Write a message',
                newRoomAlert: 'Do you want to start a conversation with this person?',
                deleteMessage: 'The message has been deleted',
                isTyping: {
                    name: '{0} is typing',
                    withoutName: 'is typing',
                },
            },
            silentModal: {
                title: 'Mute conversation',
                description: 'Do you want to mute this conversation?',
                subdescription: 'By doing this, you will not receive notifications of new messages from this person.',
            },
            unsilentModal: {
                title: 'Unmute conversation',
                description: 'Do you want to unmute this conversation?',
                subdescription: 'By doing this, you will start receiving notifications of new messages from this person again.',
            },
        },
        directory: {
            browserTitle: 'Directory',
            browserDescription: 'Community Directory',
            title: 'Directory',
            subtitle: 'Community members',
        },
        evaluation: {
            title: 'Exam',
            action: 'Take exam',
            done: 'Your exam has already been completed',
            to_done_evaluation: {
                title: 'You have not completed all the lessons',
                description: 'You need to complete at least 80% of each lesson to access the exam.',
            },
            to_done_certificate: {
                title: 'You have not taken the exam',
                description: 'To receive your certificate, you must first take the exam.',
            },
            rejected: {
                title: 'It is not possible to issue the certificate',
                description: 'You did not achieve the required passing percentage to obtain the certificate.',
            },
            quiz: {
                lessonNotAvailable: 'The lesson will be available after passing the exam',
                availableOn: 'The exam will be available on {0}',
                actions: {
                    send: {
                        title: 'Submit',
                    },
                },
                sendModal: {
                    title: 'Submit exam',
                    descriptionwithResults: 'Do you want to submit the exam and get the results?',
                    descriptionwithoutResults: 'Do you want to submit the exam?',
                    send: 'Submit',
                },
                progress: '{0} of {1}',
                results: {
                    lookupCertificates: 'All your course certificates are available in the “My Profile” section.',
                    complete: {
                        title: 'Well done, {0}!',
                        betweenLesson: {
                            certificate: 'You have completed the exam for the course {0}. Continue with the next lessons to finish the course and obtain the certificate.',
                            noCertificate: 'You have completed the exam for the course {0}. Continue with the next lessons to finish the course.',
                        },
                        finish: {
                            certificate: 'You have completed the exam for the course {0} and will receive a certificate for your achievement.',
                            noCertificate: 'You have completed the exam for the course {0}.',
                        },
                    },
                    approve: {
                        title: 'Congratulations, {0}!',
                        showCorrectAnswers: {
                            betweenLesson: {
                                certificate: 'You passed the exam for the course {0} with a {1}%. Continue with the next lessons to finish the course and obtain the certificate.',
                                noCertificate: 'You passed the exam for the course {0} with a {1}%. Continue with the next lessons to finish the course.',
                            },
                            finish: {
                                certificate: 'You passed the exam for the course {0} with a {1}% and will receive a certificate for your achievement.',
                                noCertificate: 'You passed the exam for the course {0} with a {1}%.',
                            },
                        },
                        noShowCorrectAnswers: {
                            betweenLesson: {
                                certificate: 'You passed the exam for the course {0}. Continue with the next lessons to finish the course and obtain the certificate.',
                                noCertificate: 'You passed the exam for the course {0}. Continue with the next lessons to finish the course.',
                            },
                            finish: {
                                certificate: 'You passed the exam for the course {0} and will receive a certificate for your achievement.',
                                noCertificate: 'You passed the exam for the course {0}.',
                            },
                        },
                    },
                    failure: {
                        title: 'We are sorry, {0}',
                        retry: {
                            noretries: 'We encourage you to review the content.',
                            evaluation: 'You have {0} more attempts available. We encourage you to review the content and retake the exam.',
                            noEvaluation: 'You have {0} more attempts available.',
                            maxRetriesReached: 'You have reached the maximum number of attempts allowed to complete the exam.',
                        },
                        showCorrectAnswers: {
                            betweenLesson: {
                                sequential: 'With a score of {0}%, you have not reached the required score to pass and continue with the next lessons of {1}.',
                                noSequential: 'With a score of {0}%, you have not reached the required score to pass the exam for the course. However, you can continue with the next lessons of {1}.',
                            },
                            finish: 'With a score of {0}%, you have not reached the required level to pass the exam for the course {1}.',
                        },
                        noShowCorrectAnswers: {
                            betweenLesson: {
                                sequential: 'You have not reached the required score to pass and continue with the next lessons of {0}.',
                                noSequential: 'You have not reached the required score to pass the exam for the course. However, you can continue with the next lessons of {0}.',
                            },
                            finish: 'You have not reached the required level to pass the exam for the course {0}.',
                        },
                    },
                    default: {
                        title: 'Unrecognized status',
                        description: 'The current status of the course could not be determined.',
                    },
                },
                error: {
                    empty: 'The answer cannot be left blank',
                    help1: 'It is not possible to submit the exam. All questions must be answered',
                    help2: 'There was a problem submitting the exam, please try again',
                },
            },
        },
        events: {
            browserTitle: 'Events',
            browserDescription: 'Community Events',
            title: 'Events',
            upcomingEvents: 'Upcoming events',
            tabs: {
                next: 'Upcoming',
                now: 'Now',
                old: 'Past',
            },
            empty: 'No events are scheduled at this time.',
            add: 'Create event',
            old_tag: 'Past event',
            actions: {
                accept: 'Going',
                reject: `Can't go`,
                details: 'More details',
            },
            edit: {
                title: 'Edit event',
                eventInvitations: {
                    error: 'It is not possible to edit the invitation for an event. To do so, it is necessary to create a new one.',
                },
            },
            detail: {
                description: 'Description',
                detail: 'Detail',
                attendances: 'Guests',
                empty: 'No information about the event has been added',
                attendanceNumberOne: `{0} person has confirmed his attendance.`,
                attendanceNumber: `{0} people have confirmed their attendance.`,
                attendanceAlert: `Your reply has been updated`,
                acceptedAttendances: 'Going',
                rejectedAttendances: `Can't go`,
                noreplyAttendances: 'No reply',
                goToDetails: 'Go to detail',
                enter: 'Enter to the event',
                enterInfo: 'Access will be enabled once the event starts',
                rightNow: 'Right now',
                addtoCalendar: 'Add to my calendar',
                moreDetails: 'For more details visit:',
            },
            delete: {
                title: 'Delete event',
                description: 'Do you want to delete this event?',
                subdescription: 'By doing so, you will not be able to recover it and its information will be lost.',
            },
            create: {
                title: 'Create event',
                eventBanner: { label: 'Banner image' },
                eventTitle: { label: 'Title', validations: { required: 'The event title cannot be empty' } },
                eventDateStart: { label: 'Start date' },
                eventDateEnd: { label: 'End date' },
                eventDateTimeStart: { label: 'Start datetime' },
                eventDateTimeEnd: { label: 'End datetime' },
                eventAllDay: 'All day',
                eventKind: {
                    label: 'Event type',
                    items: {
                        online: 'Online',
                        presential: 'Presential',
                    },
                },
                eventPlace: {
                    label: 'Place',
                    validations: { required: 'You most include a the place where the event will be placed' },
                },
                eventPlaceItems: {
                    zoom: 'Zoom',
                    withLink: 'With link',
                    withoutLink: 'Without link',
                },
                eventZoom: {
                    connectDescription: 'Sign in with your Zoom account to create events in your community.',
                    connectAction: 'Log in',
                    disconnectAction: 'Disconnect account',
                    connectedAccount: 'Connected Zoom account',
                    error: 'To create Zoom events log in is required',
                    disconnectModal: {
                        title: 'Do you want to disconnect this Zoom account?',
                        description: 'By doing so, you will not be able to create Zoom events and all those previously created with this option will switch to event mode without a link, that is, the invited people will not have a direct access to the event.',
                        action: 'Disconnect',
                    },
                },
                eventLink: { label: 'Event link', validations: { required: 'You most include a link to enter to the event' } },
                eventWithoutLinkAlert: 'Add in the description instructions to participate in the event',
                eventDescription: {
                    label: 'Description',
                    prompt: 'Tell the community what the event is about',
                    validations: {
                        required: 'The event description cannot be empty in a event without link',
                        short: 'Description must contain at least 3 characters',
                    },
                },
                eventInvitations: {
                    label: 'Send invitation',
                    items: {
                        community: { label: 'All the community', help: 'The invitation will be sent to all spaces of this community.' },
                        boards: {
                            placeholder: 'Select spaces',
                            label: 'Select spaces',
                            prompt: 'Type the spaces you want to invite',
                            help: 'Only people from the selected spaces will be able to see and join the event.',
                            validations: { required: 'Select whom do you want to invite' },
                        },
                        users: {
                            placeholder: 'Select members',
                            label: 'Select members',
                            prompt: 'Select who you want to send the invitation to',
                            help: 'Only selected people will be able to view and join the event.',
                            validations: { required: 'Select whom do you want to invite' },
                        },
                    },
                },
                eventEmail: {
                    title: 'Send email',
                    description: 'Notify the selected individuals via email once the event is created, 24 hours before, and when it begins.',
                },
            },
        },
        landingb2b: {
            browserTitle: 'Turingo - Build a digital community',
            browserDescription: 'Turingo - Build a digital community',
            title: 'Turingo - Build a digital community',
            headline: ['Build a digital community', 'to enhance the evolution of your organization.'],
            subheadline: 'SaaS Turingo is the platform that helps you create and manage your community by connecting with your stakeholders for the growth and success of your organization.',
            action: 'Ask for a demo',
            tabs: {
                feed: {
                    title: 'Feed',
                    description: 'Create spaces to interact and share different types of content on a specific topic.',
                },
                events: {
                    title: 'Events',
                    description: 'Boost interaction in your community through the creation of online and face-to-face events.',
                },
                chat: {
                    title: 'Messages',
                    description: 'Encourage communication among the individuals in your community through direct and private messaging.',
                },
                profile: {
                    title: 'Profile',
                    description: 'Get valuable insights and improve member visibility with personalized profiles.',
                },
                admin: {
                    title: 'Admin Dashboard',
                    description: 'Manage roles by granting personalized access to members and collaborators of your community.',
                },
                capacitation: {
                    title: 'Training',
                    description: 'Offer specialized courses to train and certify your community on specific topics.',
                },
            },
            trustus: 'They trust us',
            highlights: {
                title: 'What could you achieve by creating your community in Turingo?',
                items: [
                    {
                        title: 'Create business opportunities',
                        description: 'Create personalized and exclusive products and services for your community and innovate by connecting with other players in the ecosystem.',
                    },
                    {
                        title: 'Build loyalty in your community',
                        description: 'Improve interaction and collaboration in your community and company through constant communication and interaction between users.',
                    },
                    {
                        title: 'Strategically monetize',
                        description: 'Generate sustainable and profitable income through the creation of content, strategic sponsorships and alliances that strengthen the relationship with your members.',
                    },
                ],
            },
            demo_highlights: ['Build loyalty in your community.', 'Create business opportunities', 'Strategically monetize'],
            phrase: [
                'We bring you our',
                'technology and experience',
                'in communities to',
                'boost your business',
                'in a safe, measurable and exclusive space for your members, which helps build trust and promote collaboration with them.',
            ],
            services: {
                title: 'Products and services you will have in your community',
                items: [
                    {
                        title: 'Share personalized content',
                        description: 'Provide content for your interest groups through courses, talks, publications, events and more.',
                    },
                    {
                        title: 'Get analytics',
                        description: 'Centralize data and facilitate decision-making efficiently to grow your community.',
                    },
                    {
                        title: 'Manage your own community',
                        description: 'Collaborate and improve the management of your community with exclusive access from the administration.',
                    },
                    {
                        title: 'Events',
                        description: 'Attract and retain your audience through massive or private events and boost your community.',
                    },
                    {
                        title: 'Directory',
                        description: 'Promote collaboration by providing a space for members to connect within the community.',
                    },
                    {
                        title: 'Online course certifications',
                        description: 'Improve the value of your brand by offering online courses with certification included in your community.',
                    },
                    {
                        title: 'Direct messaging',
                        description: 'Enhance, foster communication, and strengthen relationships within your community in real-time.',
                    },
                    {
                        title: 'AI text generator',
                        description: 'Optimize content creation with Alan, featuring multiple tones and customized creative ideas for your community.',
                    },
                ],
            },
            footer: ['Create ', 'your community', 'with our SAAS Turingo!'],
            turingo_community: {
                title: 'Get to know our use case in the Turingo Entrepreneurship Community',
                action: 'Get to know Turingo Community',
            },
            demo: {
                title: 'Enter your information',
                description: 'Our sales team will contact you to provide you with more details.',
                company_name: {
                    label: 'What is the name of your company?',
                    placeholder: 'Company name',
                    validations: {
                        required: 'The company name cannot be left blank',
                    },
                },
                company_profile: {
                    label: 'What does your company do?',
                    placeholder: 'My company is dedicated to...',
                    validations: {
                        required: 'The text field cannot be left blank',
                    },
                },
                action: 'Ask for a demo',
                success: {
                    title: 'Your request has been sent successfully',
                    description: 'Our sales team will contact you soon.',
                },
                error: 'There was a problem sending your request, please try again',
            },
        },
        redeem: {
            error: {
                title: 'An error has occurred',
                description: 'It seems the entered license has already been used or has reached its maximum usage limit.',
                action: 'Go to home',
            },
        },
        admin: {
            title: 'Administrator Panel',
            browserTitle: 'Admin Panel',
            browserDescription: 'Administrator Settings for the community',
            actions: {
                abandon: 'Leave community',
                back: 'Back to community',
                adminPanel: 'Admin Panel',
            },
            notifications: {
                title: 'Notifications',
                description: 'Select the frequency with which you want to send updates from your community.',
                options: {
                    weekly: 'Weekly',
                    biweekly: 'Bi-weekly',
                    monthly: 'Monthly',
                    none: 'Never',
                },
            },
            courses: {
                title: 'Courses',
                empty: "There are no courses created",
                preamble: 'Create courses and provide valuable training for your community.',
                description: 'Create courses and provide valuable training for your community.',
                addButton: {
                    title: 'Create course',
                },
                createdCourse: 'Created courses',
                editButton: {
                    title: 'Edit course',
                },
                edit: {
                    success: 'Course updated successfully.',
                    failure: 'An error occurred while updating the course.',
                },
                publishIssues: {
                    lessonsEmpty: 'To publish a course, you must have at least one visible lesson.',
                },
                courseAddModal: {
                    title: 'Create Course',
                    fieldImage: { label: 'Image' },
                    fieldName: {
                        label: 'Name',
                        help: 'Enter the course name',
                        validations: {
                            required: 'Name cannot be blank',
                            short: 'Course name must contain at least 3 characters',
                        },
                    },
                    fieldDesc: {
                        label: 'Description',
                        help: 'Tell the community what the course is about',
                        validations: {
                            required: 'Description cannot be blank',
                        },
                    },
                    fieldTarget: { label: 'Targeted at', description: 'Add up to 3 descriptions indicating who the course is intended for.' },
                    fieldObjective: {
                        label: 'Course Objectives',
                        description: 'Add up to 3 descriptions specifying the achievements participants will reach upon completing the course.',
                    },
                    fieldMentor: {
                        label: 'Instructed by',
                        description: 'Add up to 5 individuals who instruct the course.',
                    },
                    fieldLanguage: {
                        label: 'Audio Language',
                        placeholder: 'English',
                        help: 'Select the audio language',
                        validations: {
                            required: 'Audio language cannot be blank',
                        },
                    },
                    fieldSubtitle: {
                        label: 'Subtitle',
                        placeholder: 'Select language',
                        help: 'Select subtitle languages if applicable',
                    },
                    addDescriptionButton: {
                        title: 'Add Description',
                    },
                    editDescriptionButton: {
                        title: 'Edit Description',
                    },
                    addMentorButton: {
                        title: 'Add Person',
                    },
                    editMentorButton: {
                        title: 'Edit Person',
                    },
                    detail: 'Detail',
                    errorUnique: 'Two courses with the same name cannot coexist',
                    moreInfo: [
                        'You can add lessons and more options once the course has been created.',
                        'As for permissions, those can be modified in the teams section',
                    ],
                },
                courseEditModal: {
                    title: 'Edit course',
                },
                courseDeleteModal: {
                    title: 'Delete course',
                    description: 'Are you sure you want to delete this course?',
                    disclaimer: 'This action cannot be undone.',
                },
                coursesTable: {
                    sectionTitle: 'General',
                    reminderToggle: 'Send reminder',
                    reminderToggleHint: 'After 7 days of inactivity users will be sent a reminder.',
                    columnName: 'Name',
                    columnImage: 'Image',
                    columnDescription: 'Description',
                    columnTarget: 'Targeted at',
                    columnObjective: 'Course Objectives',
                    columnMentor: 'Instructed by',
                    columnDetail: 'Course Detail',
                    columnLanguage: 'Audio Language',
                    columnSubtitles: 'Subtitles',
                    actions: {
                        edit: { title: 'Edit', description: 'Edit the general information of the course' },
                    },
                    moreInfo: 'You can edit course access from the Teams section.',
                },
                abilitiesTable: {
                    sectionTitle: 'Objectives',
                    description: 'Add up to 5 descriptions specifying the achievements that will be reached upon course completion.',
                    buttonAdd: { title: 'Add description' },
                    buttonVisible: { title: 'Public visibility' },
                    buttonReorder: { title: 'Reorder' },
                    descriptionTable: {
                        title: 'Objectives',
                        empty: 'No added descriptions',
                    },
                },
                audienceTable: {
                    sectionTitle: 'Audience',
                    description: 'Add up to 5 descriptions about what will be accomplished when finishing the course.',
                    buttonAdd: { title: 'Add description' },
                    buttonVisible: { title: 'Public visibility' },
                    buttonReorder: { title: 'Reorder' },
                    descriptionTable: {
                        title: 'Audience',
                        empty: 'No added descriptions',
                    },
                },
                evaluationTable: {
                    sectionTitle: 'Evaluation',
                    description: 'Create an exam to evaluate the skills of the participants of this course.',
                    buttonAdd: { title: 'Create exam' },
                    examSeparator: 'Exam',
                    empty: 'No created exams',
                    columnAnswer: 'Do',
                    columnSequential: 'In order',
                    columnAnyMoment: 'Any moment',
                    columnQuestionDate: 'Scheduled',
                    columnDateCreated: 'Created on {0}',
                    columnVersion: 'Version {0}',
                    columnQuestionCount: 'Number of questions',
                    columnShowCorrectAnswers: 'Show correct answers',
                    columnShowResults: 'Show results',
                    columnRepeats: 'Repeat exam',
                    columnApproval: 'Approval rating',
                    actions: {
                        buttonEditQuestions: { title: 'Edit questions' },
                        buttonEditConfig: { title: 'Edit configuration' },
                        buttonDelete: { title: 'Delete' },
                    },
                    error: {
                        fetch: 'There was a problem creating the exam, please try again',
                        exist: 'It is not possible to create the exam, we suggest checking if it has already been created',
                    },
                },
                mentorsTable: {
                    sectionTitle: 'Mentors',
                    description: 'Add up to 5 persons mentoring this course.',
                    buttonAdd: { title: 'Add person' },
                    buttonVisible: { title: 'Public visibility' },
                    buttonReorder: { title: 'Reorder' },
                    peopleAddModal: {
                        title: 'Add person',
                        avatarTitle: 'Image',
                        fieldName: { label: 'First and last name', placeholder: 'Write the name of this person' },
                        fieldJob: { label: 'Job title', placeholder: 'Write the title of this person' },
                        success: 'Person added',
                    },
                    peopleEditModal: {
                        title: 'Edit person',
                        avatarTitle: 'Image',
                        fieldName: { label: 'Names', placeholder: 'Write the name of this person' },
                        fieldJob: { label: 'Title', placeholder: 'Write the title of this person' },
                        success: 'Person edited',
                    },
                    peopleDeleteModal: {
                        title: 'Remove person',
                        description: 'Are you sure you want to remove this person?',
                        disclaimer: 'This action cannot be undone',
                        success: 'Person removed',
                    },
                    peopleTable: {
                        title: 'Mentors',
                        actionEdit: { title: 'Edit' },
                        actionDelete: { title: 'Delete' },
                        empty: 'No one assigned yet',
                    },
                },
                mentorReorderModal: {
                    title: 'Reorder mentors',
                    description: 'Alter the order of the mentors by dragging and dropping them.',
                    success: 'Mentors have been ordered',
                },
                lessonsTable: {
                    sectionTitle: 'Lessons',
                    number: 'Lesson number',
                    columnLessons: 'Created Lessons',
                    columnName: 'Name',
                    columnDescription: 'Description',
                    columnAdditionalMaterial: 'Additional material',
                    empty: 'No lessons created',
                    actions: {
                        add: {
                            title: 'Add Lesson',
                            description: 'Create lessons with key information to enhance your course and create a comprehensive learning experience.',
                        },
                        reorder: 'Reorder',
                        edit: { title: 'Edit', description: 'Edit the general information of the lesson' },
                    },
                    dragModal: {
                        title: 'Reorder Lesson',
                        preamble: 'Modify the order of the lessons by dragging and dropping them.',
                    },
                    addModal: {
                        title: 'Create Lesson',
                        add: {
                            success: 'Lesson created successfully',
                            failure: 'An error occurred while creating the lesson',
                        },
                        fieldVideo: { label: 'Lesson Video' },
                        fieldName: {
                            label: 'Name',
                            placeholder: 'Enter the lesson name',
                            validations: {
                                required: 'Name cannot be blank',
                            },
                        },
                        fieldDesc: {
                            label: 'Description',
                            placeholder: 'Tell the community what the lesson is about',
                            validations: {
                                required: 'Description cannot be blank',
                            },
                        },
                    },
                    uploading: 'Video currently being processed. A notification will be sent once this process completes',
                },
                lessonModalAdd: {
                    title: 'Add lesson',
                    fieldVideo: {
                        label: 'Video lesson',
                        validations: {
                            required: 'A video is required to create a lesson',
                        },
                    },
                    fieldName: {
                        label: 'Name',
                        help: 'Write down the name of the lesson',
                        validations: {
                            required: 'The name of the lesson cannot be left blank',
                            short: 'The name of the lesson must have at least 3 characters',
                        },
                    },
                    fieldDescription: {
                        label: 'Description',
                        help: 'Describe what the lesson is about',
                    },
                    success: 'Lesson has been created',
                    moreInfo: 'You can additional material once the lesson has been added.',
                    errorUnique: 'Two lessons with the same name cannot co-exist.',
                },
                lessonModalEdit: {
                    title: 'Edit lesson',
                    success: 'The lesson has been edited',
                },
                lessonModalDelete: {
                    title: 'Delete lesson',
                    description: 'Are you sure you want to delete this lesson? ',
                    disclaimer: 'This action cannot be undone.',
                    success: 'The lesson has been deleted.',
                },
                lessonMaterialTable: {
                    description: 'Add up to 5 documents and 5 links as additional materials as to reinforce the lesson.',
                    buttonAdd: { title: 'Add material' },
                    dropdownAdd: {
                        attachment: 'Document',
                        link: 'Link',
                    },
                    sectionLinks: 'Links',
                    sectionLinksEmpty: 'No links added',
                    sectionDocuments: 'Documents',
                    sectionDocumentsEmpty: 'No documents added',
                },
                lessonLinkAddModal: {
                    title: 'Add link',
                    titleEdit: 'Edit link',
                    description: 'Add a link and a display title',
                    fieldTitle: {
                        label: 'Title',
                        placeholder: 'Check out this site',
                        validations: {
                            required: 'Title cannot be left blank.',
                        },
                    },
                    fieldLink: {
                        label: 'Link',
                        placeholder: 'https://mysite.com',
                        validations: {
                            required: 'Link cannot be left blank',
                        },
                    },
                },
                lessonDocumentAddModal: {
                    title: 'Add document',
                    titleEdit: 'Edit document',
                    description: 'Upload a pdf, word, docx, xls, ppt, xlsx, pptx, txt, csv file of up to 10MB of size.',
                    fieldFile: {
                        label: 'Link',
                        validations: {
                            required: 'Link cannot be left blank',
                        },
                    },
                },
                lessonLinkDeleteModal: {
                    title: 'Delete link',
                    description: 'Are you sure you want to delete this link? ',
                    disclaimer: 'This action cannot be undone',
                },
                lessonDocumentDeleteModal: {
                    title: 'Delete document',
                    description: 'Are you sure you want to delete this document? ',
                    disclaimer: 'This action cannot be undone',
                },
                certificateTable: {
                    sectionTitle: 'Certificate',
                    preamble: 'Create up to 5 custom certificates or select one of the existing ones from the community.',
                    addButton: { title: 'Add certificate' },
                    buttonVisible: { title: 'Grant a certificate for this course' },
                    disclaimer: 'These certificates are shared among all courses within the community. Deleting one could affect other courses in the community',
                    actions: {
                        delete: { title: 'Delete' },
                        preview: { title: 'Show' },
                    },
                    preview: {
                        title: 'Certificate',
                        actionDelete: { title: 'Delete' },
                        actionPreview: { title: 'Show' },
                        active: {
                            zero: 'No courses assigned',
                            one: 'Active in 1 course',
                            nary: 'Active in {0} courses',
                        },
                    },
                },
                descriptionModalAdd: {
                    title: 'Add description',
                    success: 'Description has been added',
                    field: {
                        label: 'Description',
                        validations: {
                            required: 'Cannot be left blank',
                        },
                    },
                },
                descriptionModalEdit: {
                    title: 'Edit description',
                    delete: {
                        title: 'Delete description',
                        description: 'Are you sure you want to delete this description?',
                        disclaimer: "Once deleted it can't be recovered",
                        success: 'Description deleted',
                    },
                    success: 'Description has been edited',
                },
                descriptionReorderModal: {
                    title: 'Reorder description',
                    description: 'Alter the order of the descriptions by dragging and dropping them.',
                    success: 'Descriptions have been ordered',
                },
            },
            onboarding: {
                title: 'Onboarding',
                preamble: 'Discover key resources to build a successful community.',
                description: 'Select the frequency at which you want to send updates to your community.',
            },
            integrations: {
                title: 'Integrations',
                description: 'Link your community accounts and communicate with your audience through Turingo.',
                whatsapp: {
                    title: 'WhatsApp',
                    description: 'You can link your WhatsApp Business account and notify highlighted posts.',
                    help: 'To set up your WhatsApp account, contact the Turingo Support team',
                },
            },
            general: {
                title: 'General',
                color: {
                    turingo: 'Turingo',
                    red: 'Red',
                    cyan: 'Cyan',
                    blue: 'Blue',
                    green: 'Green',
                    magenta: 'Magenta',
                    orange: 'Orange',
                    volcano: 'Volcano',
                },
                editButton: {
                    title: 'Edit',
                },
                network: {
                    facebookTitle: 'Facebook',
                    instagramTitle: 'Instagram',
                    linkedinTitle: 'Linkedin',
                    twitterTitle: 'X',
                    websiteTitle: 'Website',
                    emailTitle: 'Email',
                    youtubeTitle: 'Youtube',
                },
                sectionNameTitle: 'Community name',
                sectionAboutTitle: 'About the community',
                sectionColorTitle: 'Color theme',
                sectionNetworkTitle: 'Social networks',
                sectionRulesTitle: 'Community rules',
                empty: "You haven't added any details about the community yet",
                modalEdit: {
                    title: 'Edit your community',
                    buttonAddRule: {
                        title: 'Add rule (5 maximum)',
                    },
                    fieldName: {
                        label: 'Community name',
                        placeholder: '',
                    },
                    fieldRuleTitle: {
                        label: 'Rule title',
                        validations: {
                            required: 'The rule title cannot be left blank.',
                            short: 'The rule title is too short.',
                        },
                    },
                    fieldRuleBody: {
                        label: 'Description',
                        validations: {
                            required: 'The rule description cannot be left blank.',
                            short: 'The rule description is too short.',
                        },
                    },
                    fieldEmail: {
                        label: 'Email',
                        placeholder: 'contact@company.com',
                    },
                    fieldWebsite: {
                        label: 'Website',
                        placeholder: 'https://www.company.com',
                    },
                    formRuleTitlePrefix: 'Title {0}',
                    formRuleDescPrefix: 'Description',
                },
            },
            analytics: {
                title: 'Analytics',
                preamble: 'Understand the overall performance of your community',
                description: 'Understand the overall performance of your community',
                courseTable: {
                    emptyState: 'No courses available',
                    userTable: {
                        title: 'Members list',
                        columnName: 'Name',
                        columnEmail: 'Email',
                        columnAttempts: 'Attempts',
                        columnProgress: 'Progress',
                        columnStatus: 'Status',
                        columnTeams: 'Teams',
                        userState: {
                            approved: 'Passed',
                            pending: 'Pending',
                            rejected: 'Failed',
                        },
                    },
                },
            },
            boards: {
                title: 'Spaces',
                preamble: 'Create spaces on specific topics to organize your community.',
                addButton: { title: 'Create space' },
                editButton: { title: 'Edit space' },
                boardsTable: {
                    columnName: 'Space',
                    columnDescription: 'Description',
                    columnTopics: 'Tags',
                    actions: {
                        edit: 'Edit space',
                        delete: 'Delete space',
                    },
                },
                createdBoards: 'Created spaces',
                empty: 'No spaces have been created',
                modalAdd: {
                    title: 'Create space',
                    preamble: 'Create a space to hold conversations about a specific topic and add tags to classify into subtopics.',
                    editTitle: 'Edit space',
                    fieldDesc: { label: 'Description' },
                    fieldName: {
                        label: 'Name of the space',
                        help: 'Write down the name of the space',
                        validations: {
                            required: 'The space name cannot be left blank',
                            pattern: 'The space name cannot include symbols',
                        },
                    },
                    moreInfo: 'The space will be created in an invisible mode until you provide the corresponding access from the Teams section.',
                    fieldTopics: {
                        label: 'Tags',
                        prompt: 'Add tag (max 20)',
                        validations: {
                            required: 'The tag cannot be left blank.',
                            short: 'At least one tag is required',
                            long: 'Maximum upto 20 tags of 20 letters each.',
                            pattern: 'The tag cannot include symbols',
                        },
                    },
                },
                modalDelete: {
                    title: 'Delete space',
                    preambleConfirm: 'Are you sure you want to delete this space?',
                    preambleConsequence: 'Doing so will not only delete it, but all posts contained within.',
                },
                modalMigrate: {
                    title: 'Migrate content',
                    preamble: 'Move posts between spaces while preserving interactions.',
                    description: 'Select the space to which you want to migrate the posts.',
                    alert: 'When migrating the content, it will only be visible to those with access. Once the changes are made, the content cannot be recovered.',
                    fieldBoard: {
                        label: 'Space',
                        placeholder: 'Select the new space',
                    },
                    fieldTopic: {
                        label: 'Topic',
                        placeholder: 'Select the new topic',
                        help: 'You can only select one target topic for the new space.',
                    },
                },
            },
            invitations: {
                title: 'Invitations',
                preamble: 'Invite more people to be part of your community via email.',
            },
            profiles: {
                publish: {
                    title: 'Publish changes',
                    description: 'When editing, it is necessary to publish the changes so the community can access them.',
                    alerts: {
                        success: 'The changes have been published',
                    },
                },
                exit: {
                    title: 'Exit Profile section',
                    description: 'Do you want to exit the Profile section?',
                    subdescription: 'If you do, the changes made that have not been published will be lost.',
                },
                title: 'Profile',
                description: 'Manage up to 25 fields in the community profile.',
                primaryInfo: 'Primary information',
                pinProperties: { title: 'Pinned fields', help: 'You can pin a field under the names of your community members' },
                analytics: 'Changes may take time to reflect in the analytics reports.',
                propertyTitle: 'Field management',
                columns: {
                    field: {
                        label: 'Select which column it will appear in',
                        help: 'Choose in which profile column it will appear',
                        placeholder: 'Select a column',
                        validations: {
                            required: 'The column cannot be left blank',
                        },
                    },
                    center: 'Center column',
                    right: 'Right column',
                },
                property: {
                    title: 'Fields',
                    kinds: {
                        ['string:date']: 'Date',
                        ['string:email']: 'Email',
                        ['string:url']: 'Link',
                        ['integer']: 'Number',
                        ['string:title']: 'Short text',
                        ['string']: 'Long text',
                        ['array:opt:single']: 'Single selection',
                        ['array:opt:multi']: 'Multiple selection',
                        ['boolean']: 'Checkbox',
                        ['string:phone']: 'Phone',
                        ['geo:city:id']: 'Location',
                    },
                    add: 'Add field',
                    edit: 'Edit field',
                    delete: 'Delete field',
                    archive: 'Archive field',
                    unarchive: 'Unarchive field',
                    pin: 'Pin field',
                    unpin: 'Unpin field',
                    addToOnboarding: 'Add to onboarding page',
                    removeFromOnboarding: 'Remove from onboarding page',
                    emptyArchive: 'No archived fields',
                    archive_modal: {
                        title: 'Do you want to archive the field?',
                        description: 'By doing so, it will be hidden in the following sections:',
                        action: 'Archive',
                    },
                    unarchive_modal: {
                        title: 'Do you want to unarchive the field?',
                        description: 'By doing so, it will be displayed in the following places:',
                        action: 'Unarchive',
                    },
                    pin_modal: {
                        title: 'Pin field',
                        description: 'By highlighting this field, it will be placed under the names of members in certain areas of the community. It will remain pinned under the name on the profile, in profile editing, and in the onboarding form.',
                        alerts: {
                            success: 'The field has been pinned',
                        },
                    },
                    unpin_modal: {
                        title: 'Do you want to unpin the field?',
                        description: 'By doing so, it will not be shown under the names of members in some areas of the community, but it will still appear on the profile page, profile editing window, and onboarding page.',
                        action: 'Unpin field',
                        alerts: {
                            success: 'The field has been unpinned',
                        },
                    },
                    create_modal: {
                        optionOrder: {
                            label: 'Show options in alphabetical order',
                        },
                        description: 'Complete the information for the new field that will be requested and appear in the profile section. ',
                        alerts: {
                            success: 'The field has been added',
                            success_edit: 'The field has been edited',
                        },
                        subdescription: {
                            text: 'People will be able to add text of up to 50 characters.',
                            long_text: 'People will be able to add text of up to 500 characters.',
                            arraySingle: 'People will be able to select only one option.',
                            arrayMultiple: 'People will be able to select more than one option.',
                            checkbox: 'People will be able to enable or disable the option.',
                        },
                        kind: {
                            label: 'Field type',
                            placeholder: 'Select a field',
                            help: 'Choose the type of field you want to create',
                            validations: {
                                empty: 'The field type cannot be blank',
                            },
                        },
                        name: {
                            label: 'Field name',
                            validations: {
                                empty: 'The name cannot be blank',
                            },
                        },
                        help: {
                            label: 'Help text',
                            help: 'Explain the purpose of the field',
                        },
                        date: {
                            label: 'Select the type of date to display:',
                            options: {
                                date: 'Day, month, year',
                                year: 'Year',
                            },
                        },
                        options: {
                            label: 'Options',
                            help: 'You can add up to 200 options, with a maximum of 255 characters each. Options will be displayed in alphabetical order.',
                            help2: 'Enter options separated by commas',
                            validations: {
                                empty: 'Options cannot be left blank',
                                max: 'You cannot have more than 200 options',
                                single_empty: 'The option cannot be left blank',
                                length: 'The option must be less than 256 characters',
                            },
                        },
                        restrictions: {
                            date: {
                                label: 'Allow entering:',
                                options: {
                                    onlyPastDates: {
                                        label: 'Only past dates',
                                        help: 'E.g., Birthday',
                                    },
                                    onlyFutureDates: {
                                        label: 'Only future dates',
                                        help: 'E.g., PhD completion date',
                                    },
                                    any: {
                                        label: 'Any',
                                    },
                                },
                            },
                        },
                        category: {
                            label: 'Section',
                            placeholder: 'Select a section',
                            help: 'Choose which section of the profile it will appear in',
                            validations: {
                                empty: 'The section cannot be blank',
                            },
                        },
                        required: {
                            label: 'Mark field as mandatory',
                            help: 'When selected, it will automatically appear on the onboarding page.',
                        },
                    },
                },
                category: {
                    title: 'Sections',
                    add: 'Add section',
                    edit_modal: {
                        title: 'Edit section',
                        field: {
                            label: 'Section name',
                            placeholder: 'Enter the section name',
                            validations: {
                                required: 'The section name cannot be left blank',
                            },
                        },
                        alerts: {
                            success: 'The section has been edited',
                        },
                    },
                    delete: 'Delete section',
                    archive: 'Archive section',
                    unarchive: 'Unarchive section',
                    pin: 'Pin section',
                    unpin: 'Unpin section',
                    emptyArchive: 'No archived sections',
                    empty: 'Section without fields',
                    archive_modal: {
                        title: 'Do you want to archive the section?',
                        description: 'By doing so, it will be hidden in the following sections:',
                        action: 'Archive',
                        alerts: {
                            success: 'The section has been archived',
                        },
                    },
                    unarchive_modal: {
                        title: 'Do you want to unarchive the section?',
                        description: 'By doing so, it will be shown in the following places:',
                        action: 'Unarchive',
                        alerts: {
                            success: 'The section has been unarchived',
                        },
                    },
                },
                tabs: {
                    profile: {
                        title: 'Profile page',
                        description: [
                            'Personal profile that each member will complete and others can visit.',
                            'Organize sections or fields by dragging and dropping from the left sidebar.',
                        ],
                    },
                    edit_profile: {
                        title: 'Edit profile window',
                        subtitle: 'Edit profile',
                        description: [
                            'This form will be available to members when editing their profile.',
                            'Organize the fields by dragging and dropping from the left sidebar. The order will be reflected in the directory filters.',
                        ],
                    },
                    onboarding: {
                        title: 'Onboarding page',
                        subtitle: 'Tell us about yourself',
                        help: 'Consider using 3 to 5 fields to facilitate onboarding and the conversion of new members.',
                        description: [
                            'During registration, each member will complete this form to add information to their profile.',
                            'Organize the fields by dragging and dropping from the left sidebar.',
                        ],
                    },
                    archive: {
                        title: 'Archived',
                        description: [
                            'Fields and sections archived from the profile page, edit profile form, and onboarding form.',
                            'Edit or unarchive sections and fields from the left sidebar.',
                        ],
                    },
                },
            },
            members: {
                title: 'Members',
                inviteButton: { title: 'Send invitation' },
                preamble: 'Manage the people who belong to the community.',
                actions: {
                    inviteResend: 'Resend invitation',
                    inviteEdit: 'Edit invitation',
                    inviteRemove: 'Remove invitation',
                    memberEdit: 'Edit teams',
                    memberRemove: 'Remove member',
                },
                tabs: {
                    members: {
                        title: 'Member list',
                        search: { label: 'Search', placeholder: 'Search member' },
                        table: {
                            columnName: 'Name',
                            columnEmail: 'Email',
                            columnStatus: 'Status',
                            columnTeams: 'Teams',
                            teamsEmpty: 'None',
                        },
                    },
                    invitations_email: {
                        title: 'Email invitations',
                        search: { label: 'Search', placeholder: 'Search invitation' },
                        filters: {
                            sent: 'Sent',
                            resent: 'Resent',
                            expired: 'Expired',
                        },
                        table: {
                            columnEmail: 'Email',
                            columnStatus: 'Status',
                            columnTeams: 'Teams',
                            states: {
                                sent: 'Sent',
                                resent: 'Resent',
                                expired: 'Expired',
                            },
                            teamsEmpty: 'None',
                        },
                    },
                    invitations_link: {
                        title: 'Links invitations',
                        search: { label: 'Search', placeholder: 'Search invitation' },
                        filters: {
                            active: 'Active',
                            expired: 'Expired',
                        },
                        table: {
                            formatUses: '{0} of {1}',
                            columnName: 'Name',
                            columnStatus: 'Status',
                            columnExpiration: 'Expiration',
                            columnUses: 'Uses',
                            columnTeams: 'Teams',
                            columnLink: 'Link',
                            copyLink: 'Copy link',
                            teamsEmpty: 'None',
                        },
                    },
                },
                modalAdd: {
                    action: 'Send invitation',
                    email: {
                        title: 'Email invitation',
                        preamble: 'Invite more people to join your community through a personalized email. Invitations are valid for 14 days.',
                        selectTeamTip: 'Add extra access for specific teams along with general access',
                    },
                    link: {
                        title: 'Link invitation',
                        preamble: 'Invite more people to join your community by creating a link.',
                        selectTeamTip: 'Include additional access for specific teams along with general access. To invite the Admin team, for security reasons, it must be done via email.',
                    },
                    linkSettings: 'Link settings',
                    linkPreamble: 'Select the expiration time of the link and, optionally, the maximum number of allowed uses. The link will expire once one of the following options is met:',
                    fieldExpiration: {
                        label: 'Expiration time',
                        options: {
                            '1h': '1 hour',
                            '12h': '12 hours',
                            '1d': '1 day',
                            '7d': '7 days',
                            '14d': '14 days',
                        },
                    },
                    fieldUses: {
                        label: 'Maximum uses',
                        placeholder: 'Select a option',
                        options: {
                            '10': '10 uses',
                            '25': '25 uses',
                            '50': '50 uses',
                            '100': '100 uses',
                            '200': '200 uses',
                        },
                    },
                    linkAlert: 'When editing the expiration time and/or maximum uses, the counter will restart from zero without affecting already registered users.',
                    accessDescription: 'Grant general or immediate specific team access:',
                    generalTip: "Default permissions selected in 'Team-General' for all community members",
                    generalAdminTip: "Default permissions selected in 'Team-General' for all community members more 'Team-Admin'",
                    selectTeamGeneral: 'General + Specific teams',
                    selectTeam: 'Specific teams',
                    messageDefault: 'We invite you to participate in our {0} Community, where you can expand your network, collaborate and acquire new skills as a professional.',
                    fieldInvitationTitle: 'Enter the emails separated by commas',
                    fieldInvitationHelp: 'You can send a maximum of 100 invitations',
                    fieldKeepMessage: 'Keep custom message for future invitations',
                    fieldTeams: {
                        label: 'Teams',
                        placeholder: 'Enter the teams you want to give access',
                        validations: {
                            required: 'Team selection cannot be left blank',
                        },
                    },
                    fieldName: {
                        label: 'Name',
                        help: 'Name the link for internal reference only',
                        validations: {
                            pattern: 'Can only contain letters.',
                            format: 'Must contain one space between first and last name.',
                            long: 'This name is too long, it should be up to 50 letters long.',
                            short: 'This name is too short, it should be at least 3 letters long.',
                            required: 'The name cannot be empty.',
                        },
                    },
                    fieldEmail: {
                        label: 'Email',
                        validations: {
                            format: 'Not a valid email (remember it should be name@domain.com).',
                            required: 'The email address cannot be empty.',
                            alreadyUsed: 'The invitation cannot be sent to an email already registered in the community.',
                        },
                    },
                    fieldMessage: {
                        label: 'Message',
                    },
                    fieldMessagePreamble: 'Welcome to the {0} community!',
                    fieldMessageAppendix: 'You have 14 days starting from today to activate your account.',
                    buttonAddInvite: {
                        title: 'Add invitee (10 maximum)',
                    },
                },
                modalEdit: {
                    title: 'Edit teams',
                    preambleAll: 'Edit the teams this member should belong to',
                    preambleTeams: 'Select the teams this member should belong to',
                    invitationAcceptedAlert: "Once the invitation is accepted, the person's name cannot be edited.",
                    fieldName: {
                        label: 'Name',
                        placeholder: '',
                    },
                    fieldTeams: {
                        label: 'Teams',
                        placeholder: 'Enter the teams you want to give access',
                    },
                },
                modalSuccess: {
                    title: 'Link invitation created',
                    preamble: `This link expires {0} or after {1} people create an account. Once one of these conditions is met.`,
                    access: `Anyone with this link will be able to join your community with team access to {0}.`,
                    expires: `You can create a new link if this one expires.`,
                },
                modalDeleteInviteLink: {
                    title: 'Delete invitation',
                    preambleConfirm: 'Do you want to remove the invitation?',
                    preambleConsequence: 'Once deleted, it cannot be recovered.',
                    buttonRemove: { title: 'Delete invitation' },
                },
                modalDeleteInvite: {
                    title: 'Cancel invitation',
                    preambleConfirm: 'Are you sure you wanna remove this invitation?',
                    preambleConsequence: "The email was already sent, but won't allow the invitee to join.",
                    buttonRemove: { title: 'Cancel invite' },
                },
                modalDeleteMember: {
                    title: 'Remove member',
                    preambleConfirm: 'Are you sure you wanna remove this member?',
                    preambleConsequence: 'Doing so will prevent this person from accessing the community.',
                    buttonRemove: { title: 'Remove member' },
                },
            },
            certificates: {
                title: 'Certificates',
                add: {
                    fieldName: { label: 'Name' },
                    fieldSignature: { label: 'Signature' },
                    fieldVisualization: { label: 'Visualization' },
                },
                deleteButton: {
                    title: 'Delete Certificate',
                },
                deleteModal: {
                    title: 'Delete Certificate',
                    preambleConfirm: 'Do you want to delete your certificate?',
                    preambleConsequence: "If you delete it, you won't be able to recover it.",
                },
                creator: {
                    steps: {
                        title: 'Title',
                        signature: 'Signature',
                        preview: 'Certificate',
                    },
                    title: 'New certificate',
                    sectionSignatureTitle: 'Signature {0}',
                    fieldName: {
                        label: 'Certificate name',
                        validations: {
                            required: 'The name cannot be empty',
                            short: 'The name must be at least 3 characters',
                        },
                    },
                    fieldLogo: {
                        label: 'Logo image',
                    },
                    fieldBackground: {
                        label: 'Background image',
                    },
                    fieldSignatureImage: {
                        label: 'Image',
                        validations: {
                            required: 'The signature image is required',
                        },
                    },
                    fieldSignatureName: {
                        label: 'First and last name',
                        placeholder: 'John Appleseed',
                    },
                    fieldSignatureJob: {
                        label: 'Job title',
                        placeholder: 'Director',
                    },
                    buttonAddSignature: {
                        title: 'Add signature title: ({0} maximum)',
                    },
                },
            },
            quizzes: {
                review: 'Review content',
                repeat: 'Repeat exam',
                finish: 'Finish course',
                continue: 'Continue',
                certificate: 'Get certificate',
                rightAnswer: 'Right answer',
                wrongAnswer: 'Wrong answer',
                info: 'You can decide in what order the exam appears from the lessons section.',
                addQuizModal: {
                    questionKind: 'Question of',
                    title: 'Create exam',
                    instructions: 'Add questions for the members taking the course, later on you can edit its configuration.',
                    fieldTitle: {
                        label: 'Title',
                        validations: {
                            required: 'Title cannot be left blank',
                        },
                    },
                    fieldDescription: {
                        label: 'Description',
                        validations: {
                            required: 'Description cannot be left blank',
                        },
                    },
                    fieldQuestion: {
                        label: 'Question {0} out of {1}',
                        validations: {
                            required: 'Question cannot be left blank',
                            short: 'Question must have at least one correct alternative',
                        },
                    },
                    fieldQuestionImage: {
                        label: 'Image',
                    },
                    fieldAlternative: {
                        label: 'Alternative',
                        validations: {
                            required: 'Alternative cannot be left blank',
                        },
                    },
                    buttonAddTitle: {
                        title: 'Add title',
                    },
                    buttonAddDescription: {
                        title: 'Add description',
                    },
                    buttonAddQuestion: {
                        title: 'Add question',
                    },
                    choiceSingle: 'Single choice',
                    choiceMultiple: 'Multiple choice',
                    sectionAddAlternative: 'Add alternatives and select the right answer',
                    sectionAddAlternativeMultiple: 'Add alternatives and select the right answers',
                },
                editQuizModal: {
                    title: 'Edit exam',
                    description: 'Once edited, the changes will be immediately reflected and may affect the results.',
                },
                configQuizModal: {
                    title: 'Exam settings',
                    optionShowAnswers: {
                        title: 'Show correct answers',
                        description: 'Upon submission, the correct answers will be displayed at the end of the evaluation.',
                    },
                    optionStartDate: {
                        title: 'Schedule the date and time it will be enabled',
                        hint: 'Once enabled, the exam will become public automatically',
                    },
                    optionSequential: {
                        title: 'Take the exam in order, locking the following lessons',
                    },
                    optionShowResults: {
                        title: 'Deliver exam results',
                        hint: 'Communicate whether the exam was passed or failed',
                        subtitle: 'Show correct answers',
                    },
                    optionRepeats: {
                        title: 'Allow exam retakes',
                        hint: 'Only people who fail can retake the exam',
                        subtitle: 'Enter the number of allowed retakes',
                    },
                    optionApproval: {
                        title: 'Allow an exam approval criteria',
                        subtitle: 'Enter the minimum required percentage',
                    },
                },
                deleteQuizModal: {
                    title: 'Delete examen',
                    description: 'Are you sure you want to delete this exam? ',
                    disclaimer: 'This action cannot be undone.',
                },
            },
            catalogues: {
                title: 'Catalogs',
                preamble: 'Customize your community and create catalogs for various purposes according to your needs and preferences',
                description: 'Catalog description',
                createdCatalog: 'Created catalogs',
                empty: 'No catalogs have been created',
                addButton: { title: 'Create catalog' },
                editButton: { title: 'Edit catalog' },
                add: {
                    title: 'Create catalog',
                    editTitle: 'Edit catalog',
                    preamble: 'Create a catalog by entering the main information and then add items to it.',
                    fieldName: { label: 'Title' },
                    fieldDesc: { label: 'Description' },
                    fieldIcon: { label: 'Select a icon' },
                    fieldVisibility: {
                        label: 'Public visibility',
                        placeholder: 'You can publish your catalog immediately or do it once you add the items to it.',
                    },
                    moreInfo: 'Once the catalog is created, you can add more details.',
                },
                delete: {
                    title: 'Delete Catalog',
                    preambleConfirm: 'Do you want to remove this catalog?',
                    preambleConsequence: 'By doing so, all the items published in this place will be deleted.',
                },
                items: {
                    title: 'Items',
                    empty: 'No items',
                    search: 'Search in {0}',
                    goToSite: 'Go to website',
                    addButton: { title: 'Add item' },
                    editButton: { title: 'Edit item' },
                    modalAdd: {
                        title: 'Add item',
                        preamble: 'Add information about each item to be added to the catalog list.',
                        editTitle: 'Edit item',
                        fieldName: { label: 'Title' },
                        fieldDesc: { label: 'Description' },
                        fieldImage: { label: 'Image' },
                        fieldLink: { label: 'Link' },
                    },
                    modalDelete: {
                        title: 'Delete item',
                        preambleConfirm: 'Do you want to remove this item?',
                        preambleConsequence: 'Once deleted, it cannot be recovered.',
                    },
                },
            },
            teams: {
                title: 'Teams',
                addPreamble: 'Create teams and assign access permissions for the different areas of your community.',
                addButton: { title: 'Create team' },
                teamName: 'Team {0}',
                allMembersTitle: 'Members (by default)',
                allMembersDescription: 'Define the general access rules. This applies by default to everyone who joins the community and all members without any teams.',
                allMembersButton: { title: 'Edit access' },
                defaultTeams: 'Default teams',
                createdTeams: 'Created teams',
                members: 'General',
                admins: 'Administrators',
                paid: 'Paid',
                membersTable: {
                    columnName: 'Member',
                    columnEmail: 'Email',
                    actions: {
                        addMember: 'Add member',
                        accessEdit: 'Edit access',
                        removeMember: 'Remove member',
                    },
                },
                modalAdd: {
                    title: 'Create team',
                    preamble: 'Invite members of your community to be part of the administration of the community.',
                    preamblePermissions: 'Select the spaces in which the members of this team can interact and their respective permissions.',
                    preamblePermissionsAll: 'Define the general access rules. This applies by default to everyone who joins the community and all members without any teams.',
                    fieldName: { label: 'Name for the new team' },
                    fieldMembers: { label: 'Members' },
                },
                modalEdit: {
                    title: 'Edit team',
                    fieldName: { label: 'Name' },
                },
                modalGeneralTeam: {
                    title: 'Member access',
                },
                modalDelete: {
                    title: 'Delete team',
                    buttonDelete: { title: 'Delete team' },
                    preambleConfirm: 'Do you want to delete this team?',
                    preambleConsequence: 'By doing so, the individuals who are part of it will lose the permissions associated with this team.',
                },
                modalAddMember: {
                    title: 'Add member',
                    preamble: 'Add members from the community to this team.',
                    fieldMember: {
                        label: 'Members',
                        validations: {
                            required: 'Must select at least one member',
                        },
                    },
                },
                modalRemoveMember: {
                    title: 'Remove member from team',
                    preambleConfirm: 'Are you sure you want to remove this member?',
                    preambleConsequence: 'In doing so, the member will lose all permissions granted by this team.',
                    buttonRemove: { title: 'Remove member' },
                },
                permissionsTable: {
                    comment: 'Comment',
                    moderate: 'Moderate',
                    post: 'Post',
                    list: 'Show',
                    access: 'Access',
                },
            },
        },
        home: {
            title: 'Home',
            browserTitle: 'Home',
            browserDescription: 'Turingo is the first ecosystem of digital collaborative evolution created by entrepreneurs and leaders of SMEs in Latam.',
            upcoming: {
                title: "What's new",
                classroom: 'New course',
                forum: 'New talk',
                story: 'New story',
            },
            sections: {
                title: 'Our available content',
                classroom: 'Learn with leading international speakers about innovation, digital transformation and more',
                forum: 'Watch again our live cycles in which experts share their knowledge on digital trends.',
                story: "Get inspired by stories of people who have undertaken digital entrepreneurship to solve society's problems. ",
            },
        },
        community: {
            title: 'Join the Turingo Community!',
            browserTitle: 'Turingo Community',
            browserDescription: 'Join our community, meet other entrepreneurs, share experiences and learn new skills through them.',
            description: "Keep in touch with Turingers from all over the world, 24/7, in our Telegram. Also, we're working on more and better ways to connect with the members of our community, specially those that will help you get your Entreprenurship going.",
            features: [
                {
                    title: 'Collaborative Connections',
                    description: 'Create new connections with our Turingers, experts across many industries and digital disciplines.',
                },
                {
                    title: 'Continuous Learning',
                    description: "Not only you'll learn from our courses and talks, the Turingo community also shares relevant content 24/7.",
                },
                {
                    title: 'Global Networks',
                    description: 'Our members are located around the world, adapting their realities to the challenges of the digital era.',
                },
            ],
            members: {
                title: 'Members in this space',
                description: '{0} people are part of this space.',
                action: 'More members',
            },
            card: {
                title: 'Become a member of a our Telegram Group!',
                action: 'Join the community',
            },
            postedIn: 'Posted in {0} | {1}',
            publicIn: 'Public in',
            goToEvents: 'Go to events',
        },
        boards: {
            discussion: {
                title: 'Community',
                browserTitle: '{0} - Community',
                browserDescription: 'Community in Turingo',
                subscribers: 'Members',
                details: {
                    rules: {
                        title: 'Community rules',
                        description: 'In order to maintain a safe environment, please read the basic rules of this community.',
                    },
                    filter: {
                        title: 'Filter posts by categories',
                        description: 'By choosing a category, you will only see posts about that tag',
                        action: 'Filter',
                    },
                    complete_profile: {
                        title: 'Complete your profile so the community can get to know you.',
                        action: 'Go to my profile',
                    },
                    empty: 'There are no posts in this community yet',
                    no_more: 'You are already up to date with the news of the community',
                    new: 'New posts',
                },
                post: {
                    title: {
                        singular: 'Post',
                        plural: 'Posts',
                    },
                    create: {
                        title: 'Create a post',
                        description: 'Post in community',
                        placeholder: 'What do you want to talk about?',
                        language: {
                            placeHolder: 'Por favor, selecciona idiomas',
                        },
                    },
                    edit: {
                        title: 'Edit your post',
                    },
                    spaces: {
                        action: 'Continuar',
                        title: 'Select a space',
                        description: 'Select a space where you want to post your content.',
                    },
                    topic: {
                        action: 'Select a tag',
                        title: 'Select a subject for your post',
                        description: 'By choosing a tag, you help the community find content based on themes, which will give you more visibility in specific audiences.',
                    },
                    delete: {
                        title: 'Do you want to delete this post?',
                        description: 'Once deleted, it cannot be recovered.',
                    },
                    alan: {
                        action: 'Insert',
                        title: 'Create a post',
                        description: 'Provide Alan with context about what you want to post, and he will transform your ideas into personalized posts.',
                        fieldText: {
                            placeholder: "Hello, I'm Alan, tell me what you want to post about",
                        },
                        fieldVoice: {
                            items: {
                                grateful: 'Grateful',
                                uplifting: 'Uplifting',
                                casual: 'Casual',
                                inspirational: 'Inspirational',
                                informative: 'Informative',
                                optimistic: 'Optimistic',
                                professional: 'Professional',
                            },
                            placeholder: 'Select a tone',
                        },
                        fieldResult: {
                            placeholder: 'Enter the context to generate text',
                        },
                        warning: 'There is {0}% of your plan available. For more details, contact the support team.',
                        errors: {
                            'socket.ai.prompt.short': 'The context is too short',
                            'socket.ai.prompt.long': 'The context is too long',
                            'socket.ai.malformed': 'Socket message is malformed',
                            'socket.ai.voice.unrecognized': 'Not a valid voice',
                            'socket.ai.voice.locale': 'Voice requires a locale',
                            'socket.ai.voice.badlocale': 'Not an acceptable locale',
                            'socket.ai.chatid': 'Autocompletion ID is required',
                            'application.feat.unavailable': 'This feature is not part of your plan',
                            'application.feat.cycle.notfound': 'No plan cycle found',
                            'application.feat.cycle.exceed': "It seems you've reached the maximum allowed usage. For details, please contact support.",
                        },
                    },
                    kind: {
                        image: {
                            name: 'Image',
                            placeholder: 'Add image',
                            upload: {
                                add: 'Add',
                                edit: 'Edit image',
                                single: {
                                    title: 'Add an image',
                                    description: 'Or drag and drop into this area',
                                    help: 'You can upload up a image in jpg, jpeg, png, gif, webp format with a size of up to {0}',
                                    errors: {
                                        size: 'The file exceeds the maximum limit. You can upload a image of up to {0}.',
                                        format: 'We are unable to upload that image format. You can upload in jpg, jpeg, png, gif and webp format.',
                                        big_dimensions: 'La imagen es muy grande ({0}x{1}), debe ser de {2}x{3}',
                                        small_dimensions: 'La imagen es muy pequeña ({0}x{1}), debe ser de {2}x{3}',
                                        other: 'We had a problem uploading the image, please try again.',
                                    },
                                },
                                multiple: {
                                    title: 'Add images',
                                    description: 'Or drag and drop them into this area',
                                    help: 'You can upload up to {0} images in jpg, jpeg, png, gif, webp format with a size of up to {1}',
                                    errors: {
                                        size: 'The file exceeds the maximum limit. You can upload {0} images of up to {1} each.',
                                        format: 'We are unable to upload that image format. You can upload in jpg, jpeg, png, gif and webp format.',
                                        big_dimensions: 'La imagen es muy grande ({0}x{1}), debe ser de {2}x{3}',
                                        small_dimensions: 'La imagen es muy pequeña ({0}x{1}), debe ser de {2}x{3}',
                                        other: 'We had a problem uploading the image, please try again.',
                                    },
                                },
                            },
                        },
                        video: {
                            name: 'Video',
                            placeholder: 'Add a video',
                            upload: {
                                title: 'Add a video',
                                description: 'Or drag and drop it into this area',
                                help: 'You can upload a video in mp4, mpeg, avi, webm format, with a size of up to {0}.',
                                errors: {
                                    size: 'The file exceeds the maximum limit. You can upload a video up to {0}.',
                                    format: 'We are unable to upload that video format. You can upload in mp4, mpeg, avi and webm format',
                                    other: 'We had a problem uploading the video, please try again.',
                                },
                            },
                        },
                        attachment: {
                            name: 'Document',
                            placeholder: 'Add a document',
                            upload: {
                                title: 'Add a document',
                                description: 'Or drag and drop it into this area',
                                help: 'You can upload a document in {0} format up to {1}.',
                                errors: {
                                    size: 'The file exceeds the maximum limit. You can upload a document up to {0}.',
                                    format: 'We are unable to upload that file format. You can upload in {0} format.',
                                    other: 'We had a problem uploading the document, please try again.',
                                },
                            },
                        },
                        poll: {
                            name: 'Survey',
                            placeholder: 'Add a survey',
                            results: {
                                show: 'Show results',
                                hide: 'Hide results',
                            },
                            form: {
                                title: 'Create a survey',
                                description: 'In order to maintain a safe and trustworthy environment in our community, soliciting political opinions, religion, and confidential information is not allowed',
                                question: {
                                    title: 'Your question',
                                    placeholder: 'How many people work in your company?',
                                    validations: {
                                        required: 'You need to enter a question',
                                        short: 'Your question is too short (must be more than 8 characters)',
                                        long: 'Your question is too long (must be up to 20 characters',
                                    },
                                },
                                option_validations: {
                                    required: 'You need to enter an option',
                                    short: 'The option is too short (must be more than 8 characters)',
                                    long: 'The option is too long (it must have up to 20 characters)',
                                },
                                option: [
                                    {
                                        title: 'Option 1',
                                        placeholder: '0-10 people',
                                    },
                                    {
                                        title: 'Option 2',
                                        placeholder: '11-50 people',
                                    },
                                    {
                                        title: 'Option 3',
                                        placeholder: '51-100 people',
                                    },
                                    {
                                        title: 'Option 4',
                                        placeholder: 'More than 100 people',
                                    },
                                ],
                                duration: {
                                    title: 'Survey duration',
                                    options: {
                                        day: '1 day',
                                        '3day': '3 days',
                                        week: '1 week',
                                        '2week': '2 weeks',
                                    },
                                },
                            },
                        },
                    },
                },
                comment: {
                    placeholder: 'Share your opinion',
                },
            },
            course: {
                subscribers: 'Students',
                title: 'Courses',
                browserTitle: '{0} - Course',
                browserDescription: 'Courses',
                finished: 'Congratulations on finishing the course!',
                list: {
                    card: {
                        free: 'Free',
                        watchNow: 'Play',
                        continue: 'Continue',
                        watchAgain: 'Watch again',
                        details: 'More info',
                        progress: {
                            notstarted: 'Not started',
                            inprogress: '{0} of {1} lessons completed',
                            completed: '{0} completed',
                        },
                    },
                    hero: {
                        title: 'Expand your knowledge with our online courses!',
                        separator: 'Available courses',
                        features: [
                            {
                                title: 'Multiplatform',
                                description: 'Learn at your own pace, with 24/7 unlimited access from any device.',
                            },
                            {
                                title: 'High Quality',
                                description: 'Quality video and course production, ready to give you the best learning experience possible.',
                            },
                            {
                                title: 'Unmatched Talent',
                                description: 'Recognized experts from their industries will guide you in your journey.',
                            },
                        ],
                    },
                    search: {
                        placeholder: 'Search in {0}',
                    },
                    suscriptions: {
                        title: 'My {0}',
                        empty: 'Here you can see the {0}s you have subscribed to.',
                        success: 'Very nice! You added new content to the "My {0}s" section!',
                    },
                    explore: '{0} availables',
                    create: 'New {0}',
                },
                details: {
                    mentors: 'Instructed by',
                    audience: 'Ideal for:',
                    abilities: 'At the end you will achieve:',
                },
                post: {
                    title: {
                        singular: 'Lesson',
                        plural: 'Lessons',
                    },
                },
                certificate: {
                    see: 'Show certificate',
                    example: 'Show example',
                    download: {
                        title: 'Certificate',
                        action: 'Download certificate',
                        message: 'Get your certificate, share it on social networks or add it to your Linkedin.',
                    },
                    progress: {
                        title: "You haven't finished all your lessons yet",
                        description: 'Complete the course to obtain a certificate',
                    },
                    congrats: {
                        title: 'You can now access your certificate',
                        description: 'Remember that this and all your certificates will be available in the Profile section.',
                        get: 'Go to certificate',
                    },
                    signatures: {
                        bernardo: {
                            name: 'Bernardo Morelos-Zaragoza',
                            position: 'Turingo CEO and confounder',
                        },
                        ivan: {
                            name: 'Iván Vera Muñoz',
                            position: 'Turingo Director and confounder',
                        },
                    },
                },
                rates: {
                    title: 'What did you think of the {0}?',
                    empty: 'Take the {0} and share with the community what you thought of it.',
                    empty_subscriptor: 'Share your appreciation of this {0} with the Turingo community.',
                    form: {
                        error: 'Rate the {0} by selecting the number of stars.',
                    },
                    progress: {
                        title: "You haven't finished all the lessons.",
                        description: 'Complete the following lessons to make a rating.',
                        more: 'and three more lessons remaining.',
                    },
                },
            },
            forum: {
                title: 'Talks',
                browserTitle: '{0} - Talk',
                browserDescription: 'Talks',
                subscribers: 'Participants',
                finished: 'Congratulations on finishing the talk!',
                list: {
                    card: {
                        free: 'Free',
                        watchNow: 'Play',
                        continue: 'Continue',
                        watchAgain: 'Watch again',
                        details: 'More info',
                        progress: {
                            notstarted: 'Not started',
                            inprogress: '{0} of {1} sessions completed',
                            completed: 'Talk completed',
                        },
                    },
                    hero: {
                        title: 'Wake up to the new Digital Tendencies!',
                        separator: 'Available talks',
                        features: [
                            {
                                title: 'One-hour Sessions',
                                description: 'Learn and recognize the trending digital topics of the moment in one-hour sessions.',
                            },
                            {
                                title: 'Asynchronous',
                                description: 'Join the live presentations, or watch them later from anywhere, anytime, 24/7.',
                            },
                            {
                                title: 'Unmatched Talent',
                                description: 'Recognized experts share their experience and knowledge developing in the new digital world.',
                            },
                        ],
                    },
                    search: {
                        placeholder: 'Search talk',
                    },
                    suscriptions: {
                        title: 'My {0}',
                        empty: 'Here you can see the talks you have subscribed to.',
                        success: 'Very nice! You added new content to the "My talks" section!',
                    },
                    explore: '{0} availables',
                    create: 'New talk',
                },
                details: {
                    mentors: 'Presented by',
                    audience: 'An ideal talk to',
                    abilities: 'At the end of this talk you will be able to',
                    continue: {
                        title: 'Continue learning',
                        items: [
                            'Leaders of the innovation, entrepreneurship and Digital Transformation ecosystem share the latest trends in their respective areas of development.',
                            'Connect with people who share your goals, doubts and purpose.',
                            'Learn key concepts in only five sessions of approximately one hour each.',
                            'Share, interact and join an online community of more than 4,000 people.',
                        ],
                    },
                },
                post: {
                    title: {
                        singular: 'Session',
                        plural: 'Sessions',
                    },
                },
                rates: {
                    title: 'What did you think of the talk?',
                    empty: 'Take the talk and share with the community what you thought of it.',
                    empty_subscriptor: 'Share your appreciation of this talk with the Turingo community.',
                    form: {
                        error: 'Rate the talk by selecting the number of stars.',
                    },
                    progress: {
                        title: "You haven't finished all the sessions.",
                        description: 'Complete the following sessions to make a rating.',
                        more: 'and three more sessions remaining.',
                    },
                },
            },
            story: {
                title: 'Stories',
                browserTitle: '{0} - Story',
                browserDescription: 'Stories',
                subscribers: 'Views',
                finished: 'Congratulations on finishing the story!',
                list: {
                    card: {
                        free: 'Free',
                        watchNow: 'Play',
                        continue: 'Continue',
                        watchAgain: 'Watch again',
                        details: 'More info',
                        progress: {
                            notstarted: 'Not started',
                            inprogress: 'Not completed yet',
                            completed: 'Story completed',
                        },
                    },
                    hero: {
                        title: 'Get inspired by stories from people like you',
                        separator: 'Available stories',
                        features: [
                            {
                                title: 'Short stories',
                                description: 'In 7 minutes, be inspired by entrepreneurs using their passion to make a difference.',
                            },
                            {
                                title: 'Real cases',
                                description: 'Get motivated by authentic real stories shared by members of our diverse Turingo community.',
                            },
                            {
                                title: 'Relatable stories',
                                description: 'Feel and identify yourself in the entrepreneurial journey along with personal stories.',
                            },
                        ],
                    },
                    search: {
                        placeholder: 'Search story',
                    },
                    suscriptions: {
                        title: 'My stories',
                        empty: 'Here you can see the stories with purporse you have subscribed to.',
                        success: 'Very nice! You added new content to the "My stories" section!',
                    },
                    explore: 'Available {0}',
                    create: 'New story',
                },
                post: {
                    title: {
                        singular: 'Story',
                        plural: 'Stories',
                    },
                },
            },
        },
        landing: {
            title: 'Connect, collaborate, and venture',
            browserTitle: 'Connect, collaborate, and venture',
            browserDescription: 'Turingo is the first ecosystem of digital collaborative evolution created by entrepreneurs and leaders of SMEs in Latam.',
            carrousel: {
                title: 'Check out more Turingo courses.',
                action: 'Go to courses',
                tags: {
                    highlight: 'Featured',
                    popular: 'Most viewed',
                },
            },
            drawer: 'Boost your business with Turingo!',
            free: 'Our courses',
            start: 'Become a Turinger!',
            next: 'Soon',
            headline: 'Connect, collaborate and start a business with the Turingo community',
            subheadline: 'We democratize access to entrepreneurship and innovation, in pursuit of the progress of SMEs and startups in Latin America.',
            phrases: {
                bernardo: [`"Through `, `entrepreneurship and innovation`, `we have the opportunity to positively impact our companies, family, and society"`],
                yaz: `"At Turingo we want to give access to the ecosystem of entrepreneurship and innovation through communities and their connections"`,
            },
            highlights: {
                main: 'At Turingo we offer you concrete tools to grow',
                title: 'Highlights courses',
                courses: {
                    icon: 'Training courses',
                    title: 'Learn from anywhere in the world',
                    description: 'Learn about e-commerce, digital marketing, financing and more with our exclusive content created by experts.',
                    action: 'Learn about the courses',
                },
                forums: {
                    icon: 'Talks',
                    title: 'Deepen with prominent professionals',
                    description: 'Get more knowledge about digital trends that will help you grow your business, with experts from various fields.',
                    action: 'Learn about the talks',
                },
                sucessStories: {
                    icon: 'Successful stories',
                    title: 'Get inspired by stories from people like you',
                    description: 'Learn about other startups that have successfully adapted to the digital age. If they could, you can too!',
                    action: 'Learn about the stories',
                },
            },
            benefitsTitle: 'Benefits of being part of a community of entrepreneurship',
            benefits: [
                {
                    title: 'Take the step to the next level',
                    description: 'Being part of an entrepreneurial ecosystem is what you need to have a successful business. Share your ideas with the community; receives feedback; and find out about the latest trends. Evolve in collaboration!',
                },
                {
                    title: 'Meet people like you',
                    description: "Being independent doesn't have to mean loneliness. Join the Turingo community and exchange experiences with other founders or independent professionals. Be part of a group of entrepreneurial spirit!",
                },
            ],
            features: {
                title: 'Be part of the Turingo community',
                items: [
                    {
                        title: 'Make your business grow',
                        description: 'Connect with entrepreneurs from your country and from other countries through our community. Open yourself to the possibility of closing new businesses!',
                    },
                    {
                        title: 'Create contact networks',
                        description: 'Get in touch with business leaders, representatives of the academy and experts in different areas.  Make new links!',
                    },
                    {
                        title: 'Collaborate and evolve',
                        description: 'If you want to go fast go alone, if you want to go far let’s go together. This is our motto. If you also share it, you are already a Turinger!',
                    },
                    {
                        title: 'Stay up to date',
                        description: "Find out about the latest trends in innovation, digital marketing, circular economy, financing and everything that may affect your company. Don't be left behind!",
                    },
                    {
                        title: 'Inspire others',
                        description: 'Share your experience and knowledge before an audience of thousands of people related to entrepreneurship. Use this space to make yourself known!',
                    },
                    {
                        title: 'Learn and connect in your language',
                        description: 'We support the growth of businesses and SMEs in Spanish-speaking countries through courses, talks and a community in Spanish. We speak your language!',
                    },
                ],
            },
            soon: {
                title: 'Highlights',
                course: 'NEW COURSE',
                forum: 'NEW TALK',
                story: 'NEW STORY',
                soon: 'COMING SOON',
                release: 'RELEASE',
            },
            blog: { title: "Know what's new in our blog", action: 'Read the blog' },
            banner: { title: 'NEW STORY WITH PURPOSE: "De La Carne"' },
            bannerValidate: {
                title: 'You have not yet confirmed your contact email. ',
                description: 'Did you not receive it?',
                action: 'Resend email',
                sended: 'We have resend the verification email, check your inbox.',
            },
            reviews: {
                title: 'What is said about our courses?',
                items: [
                    {
                        course: 'Course: The art of making your business profitable',
                        content: 'It gave me a clearer idea of how to manage the finances of my small business, thank you very much.',
                    },
                    {
                        course: 'Course: Creativity in new businesses',
                        content: 'I liked it because it is very dynamic and its cases are real, they serve as inspiration for us to create our own ideas.',
                    },
                    {
                        course: 'Course: E-Commerce',
                        content: 'Excellent opportunity to learn and develop skills in the area of business, advertising and digital marketing.',
                    },
                    {
                        course: 'Course: Digital tools for your company',
                        content: 'This course is very complete, we acquired knowledge that is important when projecting a better vision for our projects or companies.',
                    },
                    {
                        course: 'Course: How to beat the algorithm?',
                        content: 'We are in the age of technology and it is extremely important for us, professionals and entrepreneurs, to know how to use them.',
                    },
                    {
                        course: 'Course: Female Entrepreneurship',
                        content: 'I liked it because I was able to do it at my own pace, at home and without rushing. Good content.',
                    },
                ],
            },
        },
        help: {
            title: 'Frequently asked Questions',
            browserTitle: 'FAQ',
            browserDescription: 'Create a collaborative ecosystem through communities of entrepreneurs and SME leaders.',
            tabs: {
                general: {
                    title: 'General',
                    description: 'Frequent Questions',
                    questions: [
                        {
                            title: 'Why was Turingo born?',
                            answer: 'We created Turingo in 2018 with the conviction that through innovation, digitization and internationalization, we can improve our lives and lives around us. Entrepreneurs and SME  leaders are a fundamental engine to achieve this goal.',
                        },
                        {
                            title: 'What is its purpose?',
                            answer: 'At Turingo, our purpose is for entrepreneurs and SME leaders to develop the necessary capacities to successfully adapt, transcend and evolve in the fast-paced digital age, regardless of their location.',
                        },
                        {
                            title: 'Does free content have a limited time to be viewed?',
                            answer: 'No, you can review it whenever you want, and as many times as you want.',
                        },
                    ],
                },
                courses: {
                    title: 'Courses and community',
                    description: 'Frequent Questions',
                    questions: [
                        {
                            title: 'What is Turingo?',
                            answer: "Turingo is a platform for entrepreneurs, entrepreneurs and leaders of SMEs, which offers a space for collaboration, networking, learning and support to adapt to the digital age in Latin America.\n\nIf you want to learn a new skill that can help you in your business or business idea, take one of our courses by following these steps:\n\n- Sign up for free \n- Choose the course that you like \n- Click on 'Play'",
                        },
                        {
                            title: 'What is the Turingo community?',
                            answer: 'It is a space where entrepreneurs and leaders of SMEs from Latin America can share content about entrepreneurship, innovation, digitization and everything they think may be useful to help other entrepreneurs get ahead.\n\nAll members of our community have access to our pre-recorded courses, talks and posting wall.',
                        },
                        {
                            title: 'What are our community policies?',
                            answer: '1. Be kind and courteous.\n2. Do not bully or use offensive language.\n3. Do not post promotions or spam.',
                        },
                        {
                            title: 'Is the Turingo community free?',
                            answer: 'Yes, the Turingo community is free.',
                        },
                        {
                            title: 'What should I do to have my own community in Turingo?',
                            answer: 'You must write to contacto@turingo.com',
                        },
                        {
                            title: 'How can I take a course?',
                            answer: "To take a course, you just have to go to the 'Available courses' section, click on 'More info' on the course you want to see and then click on 'Watch now'. Once you start the course, you can find it in 'My courses' section of your account, and see it as many times as you want.",
                        },
                        {
                            title: 'Are all courses free of charge?',
                            answer: 'In our catalog you can find free courses and paid courses. The free courses are for beginners, if you need to go deeper in a particular subject you can take our paid courses.',
                        },
                        {
                            title: 'Are all the courses recorded or are there some live?',
                            answer: 'All courses are asynchronous; this means that you can complete them at your own pace, from wherever you want and from any device with internet.',
                        },
                        {
                            title: 'Is there a certain time to complete a course?',
                            answer: 'No, you can finish it at your own pace, when you can and from where you want.',
                        },
                        {
                            title: 'What happens if I take a course and do not finish it?',
                            answer: 'What will happen is that you will not be able to obtain your course completion certificate. We will always suggest you finish your courses to, in addition to learning, obtain the certificate that can help you validate this new acquisition of knowledge.',
                        },
                        {
                            title: 'How do I get a course completion certificate and where can I see my certificates?',
                            answer: 'At the end of any Turingo course, we will notify you that your certificate is ready, either to view it from your account, to download it, share it on your social networks, to validate skills, or for what you deem appropriate.',
                        },
                    ],
                },
                forums: {
                    title: 'Talks',
                    description: 'Frequent Questions',
                    questions: [
                        {
                            title: 'What is a reflection talk?',
                            answer: 'They are online sessions in which prominent exponents on topics of innovation, entrepreneurship, digital transformation, among others, meet to share the latest trends in these topics. During the sessions, you can interact live with speakers and with the entire attending Turingo community.',
                        },
                        {
                            title: 'Are they free of charge?',
                            answer: 'Yes, they are completely free of charge.',
                        },
                        {
                            title: 'How do I register for a talk?',
                            answer: 'Through our social networks (Instagram, Twitter, Facebook and Linkedin). We will inform in advance the ways in which you can register.',
                        },
                        {
                            title: 'Can I re-review it at any time?',
                            answer: 'Yes. In Turingo we host the talks that we carry out in our "Talks" section. You can review them as many times as you like.',
                        },
                        {
                            title: 'Do I get a certificate if I participate in a full talk?',
                            answer: 'No, only by taking a course you can obtain certificates.',
                        },
                    ],
                },
                stories: {
                    title: 'Stories',
                    description: 'Frequent Questions',
                    questions: [
                        {
                            title: "Why are they called 'Story with Purpose'",
                            answer: 'We name them that way because they are stories of entrepreneurships that, driven by a clear purpose, have managed to overcome the difficulties of undertaking and, above all, of successfully digitizing their businesses in this dizzying digital age.',
                        },
                        {
                            title: 'What are they?',
                            answer: 'In brief audiovisual capsules we present inspiring stories of leaders of SMEs and entrepreneurs, who will tell you in first person and in a close way their experience. We hope the next story you see here is yours!',
                        },
                        {
                            title: 'Can I suggest an entrepreneurship for an Story with Purpose?',
                            answer: 'Of course, you can! You can send us your suggestions through our contact form, which you can access from the section My account → Help → Contact us.',
                        },
                        {
                            title: 'Are they free of charge?',
                            answer: 'Yes, they are free, just like our talks.',
                        },
                    ],
                },
                events: {
                    title: 'Events',
                    description: 'Frequent Questions',
                    questions: [
                        {
                            title: 'What is an event?',
                            answer: 'An event is an offline or online instance where the members of the community, or space(s) can come together and see each other at an specific place and time.',
                        },
                        {
                            title: 'How do I create an event?',
                            answer: 'Be warned that only Administrators can create events (subject to change). To create an event go to main menu, select "Events" and click "Create Event"',
                        },
                        {
                            title: 'Can I use Zoom?',
                            answer: 'Of course! Zoom has been integrated into the platform allowing instantly creating a Zoom meeting for the specific date. The creator of the event will have its Zoom account linked to the event.',
                        },
                        {
                            title: 'What happens if I add Zoom?',
                            answer: 'Whenever you create an event in Turingo and use the Zoom option, it will also schedule a meeting for the same date and time automatically on Zoom. Also, on the date of the event, invited users to the event will be sent a link directly to the Zoom meeting.',
                        },
                        {
                            title: 'Do I need to add Zoom everytime?',
                            answer: 'Not at all, Turingo stores your Zoom session so you can reuse it anytime. By the same token, you can remove this integration at any time.',
                        },
                        {
                            title: 'How do I add the Zoom integration?',
                            answer: 'In the event creation form you can:',
                            steps: [
                                'Scroll to the "Place" section',
                                'Select the Zoom option and click on Add Zoom',
                                'Login to your Zoom account, either company or personal',
                                'Once logged in, the event will be associated to your Zoom account and you can reuse it as many times as you want.',
                            ],
                        },
                        {
                            title: 'How do I remove my Zoom integration?',
                            answer: 'You can either create a new event and remove the current integration, or directly through Zoom following these steps:',
                            steps: [
                                'Login to your Zoom account and go to the  App Marketplace',
                                'Click on "Manage" and go to "Added Apps", search for the App "Turingo Community"',
                                'Click on "Turingo Community"',
                                'Select remove',
                            ],
                        },
                        {
                            title: 'Are there consequences of removing Zoom?',
                            answer: "Nothing major, you won't be able to create Zoom meetings on Turingo with that account until you add it again. Any existing eventson Turingo and scheduled meetings on Zoom won't be deleted and will behave normally. If you wish, you can manually delete these events and meetings both in Zoom and in Turingo",
                        },
                    ],
                },
                account: {
                    title: 'My account',
                    description: 'Frequent Questions',
                    questions: [
                        {
                            title: 'How to create an account in Turingo?',
                            answer: "To create an account in Turingo, you just have to go to turingo.com, click on the button 'Sign up for free' and write your name, email and a password.\nYou can also register with your Gmail, Facebook or Linkedin account.",
                        },
                        {
                            title: 'How to edit my account in Turingo.com?',
                            answer: "To edit your Turingo account, log in to turingo.com. Then click on ‘’my account'’, located in the upper right corner. Then click on ''My Profile''. Once in your profile, press the button 'Edit profile'. There you can edit your position, current location, your email, your contact telephone number and your website, among others.",
                        },
                        {
                            title: 'Why do I have to write my full name on my Turingo profile?',
                            answer: 'Because we need this information in advance in order to give you the certificate with your name written on it when you complete your course.',
                        },
                        {
                            title: 'How to validate the email address associated with my Turingo account?',
                            answer: "When you register at turingo.com you will receive an email asking you to validate your account. All you have to do is click on the 'Validate account' button.",
                        },
                        {
                            title: 'How to change language preferences in Turingo?',
                            answer: "To change the language preferences you must click on 'My account'' and then go to ''Settings’’. There you can choose if you want your account to be in Spanish or English.",
                        },
                        {
                            title: 'How to change the appearance of Turingo? Dark/Light',
                            answer: "To change the appearance of Turingo you must click on 'My account' and then go to 'Settings'.",
                        },
                        {
                            title: 'I entered my data incorrectly when I registered, can I modify them?',
                            answer: 'Yes. You can modify them by going to My account → Settings. There you can change your registration email and password.',
                        },
                        {
                            title: 'Can I change the email with which I registered?',
                            answer: 'Of course! You can do it in My account → Settings → Change registration email.',
                        },
                        {
                            title: 'Is my registration email the same as the one that will appear in the "Contact" section of my profile?',
                            answer: 'You can have the same or use a different one. In any case, you must complete the information on your profile so that other people in the community can contact you..',
                        },
                        {
                            title: 'Can I access my certificates of courses taken at any time?',
                            answer: 'Yes, they will always be available, once you log in, in My account → Profile. You can download them to share them on social networks, or wherever you need it.',
                        },
                        {
                            title: 'Can I delete my turingo.com account?',
                            answer: 'If you wish, you can do so by requesting it through our contact form.',
                        },
                        {
                            title: 'How can I recover or change my password?',
                            answer: "If you want to change your password, you just have to go to My account → Settings → Change password. If you forgot it, you can click on 'Forgot your password?' In this same section, or when you log in.",
                        },
                    ],
                },
                contactus: {
                    title: 'Contact us',
                    description: 'Need help? Fill out this form to contact us.',
                    name: {
                        label: 'What are your first name and surname?',
                        placeholder: 'Johnny Seed',
                    },
                    email: {
                        label: 'What is your email address?',
                        placeholder: 'name@email.com',
                    },
                    subject: {
                        label: 'Why are you contacting us?',
                        placeholder: "I try to change my registered email but I can't do it.",
                    },
                    message: {
                        label: 'Tell us more details',
                        placeholder: 'The system does not save my email change and I tried several times. I need you to do it',
                    },
                    action: { title: 'Send message' },
                },
            },
        },
        settings: {
            title: 'Settings',
            browserTitle: 'Settings',
            browserDescription: 'Settings',
            sections: {
                language: {
                    title: 'Language',
                    description: 'Select the default language for your account.',
                    options: {
                        es: 'Spanish',
                        en: 'English',
                        pt: 'Portuguese',
                    },
                },
                theme: {
                    title: 'Appearance',
                    description: 'Select the default appearance for your account.',
                    options: {
                        light: 'Light mode',
                        dark: 'Dark mode',
                    },
                },
                notifications: {
                    title: 'Notifications',
                    activity: {
                        title: 'New activity',
                        sound: {
                            title: 'Sound',
                            description: 'Play a sound when you receive a new message.',
                        },
                    },
                    email: {
                        title: 'Email',
                        alert: "Featured posts will be sent even if you've disabled email notifications, due to their significance",
                        chat: {
                            title: 'Messages',
                            description: 'Receive emails when you are sent messages.',
                        },
                        event: {
                            title: 'Events',
                            description: 'Receive emails when you are invited to events and reminders.',
                        },
                        digest: {
                            title: 'Community updates',
                            description: 'Receive emails with the latest news from your community.',
                        },
                    },
                    direct_message: {
                        title: 'Instant Messaging',
                        whatsapp: {
                            help: 'To receive messages, add your phone number to your profile',
                            title: 'WhatsApp',
                            description: 'Receive notifications for highlighted posts.',
                        },
                    },
                },
                signup: {
                    title: 'Account',
                    changeEmail: {
                        title: 'Email',
                        description: 'Registration email',
                        help: 'It is not visible to the community, and with it, you can recover or change your password.',
                        action: 'Change',
                        modal: {
                            title: 'Change email',
                            description: 'We will send you an email to your new address to verify it.',
                            password: {
                                label: 'To confirm changes, enter your password',
                                placeholder: '•••••••••',
                            },
                            email: {
                                label: 'Write your new email address',
                                placeholder: 'name@email.com',
                            },
                            actions: {
                                cancel: 'Back',
                                accept: 'Change email',
                            },
                        },
                        modal_create: {
                            title: 'Email change',
                            description: 'Write your new email and create your password to be able to log in in the future. We will send you an email to your new address to verify it.',
                            password: {
                                label: 'Create your password',
                                placeholder: '•••••••••',
                            },
                            email: {
                                label: 'Write your new email',
                                placeholder: 'name@email.com',
                            },
                            actions: {
                                cancel: 'Cancelar',
                                accept: 'Cambiar',
                            },
                        },
                        success: {
                            title: 'We have sent you an email',
                            description: 'Check your inbox to verify your new address.',
                            action: 'OK',
                        },
                    },
                    changePassword: {
                        title: 'Password',
                        description: 'You can change your password if you want',
                        description_not_setted: 'You have not yet created a password. Create now.',
                        action: 'Change',
                        modal: {
                            title: 'Change password',
                            description: 'First, enter your current password, then your new password. ',
                            oldPassword: {
                                label: 'Write your current password',
                                placeholder: '•••••••••',
                            },
                            newPassword: {
                                label: 'Write your new password',
                                placeholder: '•••••••••',
                            },
                            actions: {
                                cancel: 'Back',
                                accept: 'Change password',
                            },
                        },
                        modal_create: {
                            title: 'Create password',
                            description: 'Enter your password to be able to log in in the future.',
                            newPassword: {
                                label: 'Create your password',
                                placeholder: '•••••••••',
                            },
                            actions: {
                                cancel: 'Cancel',
                                accept: 'Create password',
                            },
                        },
                        success_create: {
                            title: 'Your password has been successfully created.',
                            description: 'From now you can log in with your email and password.',
                            action: 'OK',
                        },
                        success: {
                            title: 'Password successfully modified',
                            description: 'Now you can access with your new password.',
                            action: 'OK',
                        },
                        error: {
                            insufficientChar: 'Must be at least 5 characters long',
                        },
                    },
                    changePhone: {
                        title: 'Registration phone',
                        help: 'It is not visible to the community, and with it, you will receive notifications of featured posts via WhatsApp',
                        description: 'Enter your new phone number to receive notifications on WhatsApp in the future.',
                        description_not_setted: 'You have not added a phone number yet.',
                        modal: {
                            title: 'Edit phone',
                            description: 'Enter your new phone number to receive notifications on WhatsApp in the future.',
                            phone: {
                                label: 'Enter your new phone number',
                            },
                        },
                        success: 'The phone number has been updated',
                    },
                },
                accounts: {
                    title: 'Connected accounts',
                    social: {
                        connected: 'Your {0} account is currently linked to your Turingo account.',
                        disconnected: 'You can link your {0} account and log in to Turingo.',
                    },
                    modals: {
                        added: {
                            success: {
                                title: 'Your account has been successfully added.',
                                description: 'From now on you can log in with your {0} account.',
                                action: 'OK',
                            },
                            warning: {
                                title: 'This account is already linked to another Turingo account.',
                                description: 'Check if you have another Turingo account that is linked to your {0} account. If the problem persists, contact us at contacto@turingo.com.',
                                action: 'OK',
                            },
                        },
                    },
                    delete: {
                        title: 'Delete account',
                        description: 'Do you want to delete your account?',
                        subdescriptionOne: 'Once deleted, it cannot be recovered and you will not have access to the messages, contacts and spaces of the community to which you belong.',
                        subdescriptionMany: 'Once deleted, it cannot be recovered and you will not have access to the messages, contacts and spaces of the communities to which you belong.',
                        content: 'If you wish to proceed, you will be logged out on all your devices, and your account information will be lost.',
                        confirm: 'I confirm that I want to delete my account',
                        keep: 'Keep account',
                    },
                    error: {
                        part1: 'You need to have at least one linked account or you can',
                        part2: 'create a password',
                        part3: 'to be able to log in in the future.',
                    },
                },
            },
        },
        profile: {
            title: 'Profile',
            browserTitle: 'Profile',
            browserDescription: 'Profile',
            shareButton: 'Share profile',
            notAdded: 'Information not added',
            editButton: 'Edit',
            empty: 'This person has not completed their data.',
            aboutMe: {
                title: 'Biography',
                description_owner: 'Add a description about you',
            },
            segments: {
                service: {
                    title: 'Offered Services',
                    description_owner: 'Add the services you offer',
                },
                industry: {
                    title: 'Experience Industry',
                    description_owner: 'Add the industries you have worked in',
                },
                interest: {
                    title: 'Interests',
                    description_owner: 'Add your interests',
                },
            },
            jobCard: {
                title: 'Training and Career',
                placeholder: 'Add the details about your training and career',
            },
            certificates: {
                title: 'Certificates',
                description_owner: 'Your course certificates will show here',
                description_empty: 'This person has not yet obtained certificates',
            },
            contactCard: {
                title: 'Contact',
                email: { title: 'Email', tooltip: 'Copy email', message: 'The email has been copied' },
                phone: { title: 'Phone', tooltip: 'Copy phone', message: 'The phone has been copied' },
                website: { title: 'Website', tooltip: 'Open website' },
                prompt: 'Add your contact information',
            },
            editModal: {
                title: 'Edit Profile',
                avatar: 'Edit profile picture',
                banner: 'Edit banner',
                name: {
                    label: 'First and last name',
                    placeholder: 'Claudia Urrutia',
                    prompt: 'Enter your first and last name',
                },
                position: {
                    label: 'Occupation',
                    placeholder: 'Journalist in entrepreneurship magazine',
                    prompt: 'Tell the community where you work and what your occupation is.',
                    validations: {
                        required: 'The occupation cannot be blank',
                        long: 'Your occupation is too long, it should have at must 50 characters',
                        short: 'Your occupation is too short, it should have at least 3 characters',
                    },
                },
                location: {
                    label: 'Current location',
                    placeholder: 'Santiago, Chile',
                    prompt: 'Where you currently live?',
                    validations: {
                        required: 'The location cannot be blank',
                    },
                },
                aboutMe: {
                    label: 'About me',
                    placeholder: "I'm 50 years old, I've been in charge of startups for more than 15 years...",
                    prompt: 'Tell the community who you are',
                },
                email: {
                    label: 'Public email',
                    placeholder: 'your-account@email.com',
                    prompt: 'Add your contact email',
                    help: 'It will be displayed to the community as the contact email.',
                    validations: {
                        required: 'The public email cannot be blank',
                    },
                },
                phone: {
                    help: 'By adding your phone number, you will receive notifications only for highlighted posts on your WhatsApp. To deactivate them, adjust the notifications in your account',
                    label: 'Contact number',
                    placeholder: '+569 9 1234 567',
                    prompt: 'Share your number with the community',
                    validations: {
                        general: 'Your phone number is not valid',
                    },
                    description: 'Hide number from the community',
                },
                website: {
                    label: 'Website',
                    placeholder: 'www.your-website.com',
                    prompt: 'Share the link to your website',
                    validations: {
                        short: 'Your link is too short, it must have at least 5 characters',
                        long: 'Your link is too long, it must have at most 100 characters',
                        pattern: 'Your link must be a valid url',
                    },
                },
                services: {
                    label: 'Services Offered',
                    placeholder: 'Select the services you offer',
                    validations: {
                        long: 'You can only choose up to 3 services',
                    },
                },
                industries: {
                    label: 'Industry Experience',
                    placeholder: 'Select the industries worked in',
                    validations: {
                        long: 'You can only choose up to 3 industries',
                    },
                },
                interests: {
                    label: 'Interests',
                    placeholder: 'Select your interests',
                    validations: {
                        long: 'You can only choose up to 3 interests',
                    },
                },
                private_account: 'Private account',
                actions: {
                    cancel: 'Cancel',
                    accept: 'Save',
                },
            },
        },
        about_us: {
            title: 'About us',
            mision: {
                title: 'Mission',
                description: 'Decode a new communication and collaboration between actors and stakeholders in the business world in the dizzying digital age.',
            },
            vision: {
                title: 'Vision',
                description: 'Create the first digital ecosystem of collaborative business evolution in Latin America.',
            },
            purpose: {
                title: 'Purpose',
                description: 'Our engine is the progress of Latin America. We promote democratized access to the business, entrepreneurship, and innovation ecosystem, improving its sustainability and impact on the economy and society.',
            },
            history: {
                title: 'Our story',
                description: 'Turingo was born in October 2018 with the purpose that entrepreneurs and leaders of SMEs acquire new tools and skills to digitize their businesses through online courses and content specially created to achieve this goal.\n\nNowadays, Turingo is focused on building digital communities to develop ecosystems of collaborative evolution.',
            },
            whyus: {
                title: ' What is Turingo?',
                description: 'Turingo is a software as a service where you can create and manage a community that connects your groups of interest, which will make it possible to positively influence them to drive the growth and success of your organization.\n\nWe bring you our technology and experience in communities to boost your business, in a safe, measurable, and exclusive space for your members, which helps build trust and encourage collaboration.',
            },
            ourteam: {
                title: 'Our team',
            },
            items: [
                {
                    title: 'We are digital nomads',
                    description: 'We work from anywhere in Chile and the world, always obtaining the best results, regardless of our locations.',
                },
                {
                    title: 'Collaboration',
                    description: 'We know that teamwork is key to learn, innovate and take off in the digital era, and we apply it every day.',
                },
            ],
        },
        auth: {
            signup: {
                title: 'Become a Turinger!',
                browserTitle: 'Sign Up',
                browserDescription: 'Become a Turinger',
                action: 'Sign up for free',
                mobileAction: 'Join us',
                community: 'Sign up in the {0} community',
                social: {
                    facebook: 'Signup with Facebook',
                    google: 'Signup with Google',
                    linkedin: 'Signup with Linkedin',
                    zoom: 'Crea una cuenta',
                },
                goLogin: {
                    ask: 'Already have an account?',
                    action: 'Log In',
                },
                error: {
                    fetch: 'A problem has occurred',
                    email: 'Email already taken',
                    missingParams: 'this params are invalids, try again',
                },
                goBack: 'Back',
                free: 'Become a Turinger',
                policies: {
                    previousMessage: 'If you sign up, you accept our',
                    conditions: 'Terms and conditions.',
                    and: 'and',
                    privacy: 'Privacy policy',
                },
                leftPanel: {
                    onlineCourses: 'Online Courses',
                    onlineCoursesDesc: 'Learn anywhere in the world',
                    chat: 'Talks',
                    chatDesc: 'Deepen your knowledge with renown masters',
                    conceptSharing: 'Stories with purpose',
                    conceptSharingDesc: 'Get inspired from entrepreneurs like you',
                },
                conflict_modal: {
                    title: 'Account conflict',
                    description: 'There is another Turingo account with the email {0}. Would you like to link both accounts?',
                    subdescription: 'We will send you a confirmation email to verify your data.',
                    action: 'Link accounts',
                },
                conflict_modal_success: {
                    title: 'We have sent you an email',
                    description: 'Check your inbox and follow the instructions to link both accounts.',
                },
            },
            login: {
                browserTitle: 'Login',
                browserDescription: 'Turingo Login',
                mobileAction: 'Login',
                social: {
                    facebook: 'Continue with Facebook',
                    google: 'Continue with Google',
                    linkedin: 'Continue with Linkedin',
                    zoom: 'Inicia sesión',
                },
                title: 'Login',
                action: 'Log In',
                goSignup: {
                    ask: "Don't have an Account?",
                    action: 'Create a free account',
                },
                forgotenPassword: 'Forgot your Password?',
                error: 'No account with this email address and password combination.',
                authExpired: 'Your session has expired',
                greetings: 'Hello again Turinger!',
                goLogin: 'Log In.',
                goBack: 'Back',
            },
            logout: {
                title: 'Are you sure you want to log out?',
                description: 'You can always log back in at any time.',
                actions: {
                    accept: 'Yes, log out',
                    cancel: 'No, keep me in',
                },
            },
            expiration: {
                title: 'Your session in Turingo has expired',
                description: 'For security reasons, we need you to log in again with your Turingo account.',
                actions: {
                    accept: 'Log In',
                },
            },
            validation: {
                title: 'Validate your account',
                success: {
                    title: 'Thanks for activating your account!',
                    description: "From now on you'll be able to access our online courses and talks, plus all of our contents",
                    action: 'Explore our content',
                },
                error: {
                    title: 'An error has occurred',
                    description: 'Sorry, we were unable to validate your account, please try again later. If the problem persists, contact us at contacto@turingo.com.',
                    action: 'Back to home',
                },
            },
            verification: {
                title: 'Verify your account',
                success: {
                    title: 'Your account has been verified successfully',
                    description: 'You are now ready to log in',
                    action: 'Explore our content',
                },
                error: {
                    title: 'An error has occurred',
                    description: "We're sorry, we were unable to verify your account, please try again later. If the problem persists contact us at contacto@turingo.com.",
                    action: 'Back to home',
                },
            },
            recover: {
                title: 'Forgot your password?',
                notFound: 'No account with this email address.',
                description: 'Enter your email to create a new password',
                action: 'Recover password',
                success: {
                    title: "We've sent you an email with instructions",
                    description: 'Check your inbox and follow the instructions to reset your password',
                    action: 'Back to home',
                },
            },
            recover_confirm: {
                title: 'Create your new password',
                description: 'You are close to create a new password for your account',
                action: 'Create password',
                success: {
                    title: 'Your password has been reset',
                    description: "You're all set to use your account",
                    action: 'Back to login',
                },
            },
            blocked: {
                title: 'Your account has been temporarily blocked',
                description: 'You need to validate your email to continue using your Turingo account.',
                actions: {
                    resendEmail: 'Resend email validation',
                    logout: 'Logout',
                },
                success: {
                    title: 'We sent you an email',
                    description: 'Check your inbox to validate your account.',
                },
                error: {
                    title: 'An error occurred',
                    description: 'There was an error resending the validation email.',
                },
            },
        },
        billing: {
            title: 'Purchase',
            browserTitle: 'Shopping cart',
            browserDescription: 'Your purchase in Turingo',
            stepper: {
                select: 'Select',
                pay: 'Pay',
                enjoy: 'Enjoy',
            },
            payment: {
                title: 'Payment information',
                input: {
                    email: {
                        tooltip: 'We need your email to have a means of contact with any questions or problems with your purchase.',
                        label: 'What is your email?',
                        placeholder: 'youraccount@email.com',
                    },
                    country: {
                        label: 'What is your country of purchase?',
                        placeholder: 'Select a country',
                        required: 'You must select a country',
                    },
                },
            },
            dlocal: {
                info: 'Turingo will never store your payment information.',
                pan: {
                    label: 'Card number',
                    empty: 'You need to enter your card number.',
                },
                expiration: {
                    label: 'Expiration date',
                    empty: 'You need to enter your card expiration date.',
                    invalid: 'Your card has expired.',
                    placeholder: 'MM/YY',
                },
                cvv: {
                    label: 'Verification code',
                    empty: 'You need to enter the verification code (CVV).',
                },
                name: {
                    empty: "You need to enter the cardholder's name.",
                    format: 'You can only include letters, hyphen, dots and apostrophe.',
                },
                dni: {
                    empty: 'You need to enter your number ID.',
                    format: 'Please enter a valid number ID.',
                },
                other: {
                    empty: 'This field is required.',
                    format: 'The field is not in the correct format.',
                },
            },
            paymentMethod: {
                title: 'Payment method',
                methods: {
                    paypal: "By using PayPal Checkout, we will send you to PayPal's secure servers. PayPal payments are made in USD ($) and the amount you see in your local currency is an approximate that may vary according to your bank and the exchange rate of the day.",
                    dlocalgo: 'By choosing this payment method, we will redirect you to the secure payment provider called DLocal, which offers a wide variety of payment options.',
                },
                more: '+{0} more',
            },
            purchaseDetail: {
                title: 'Purchase detail',
                course: 'Course',
                currency: {
                    USD: 'US$',
                },
                warning: {
                    description: {
                        title: 'By making the payment you accept the',
                        terms: 'Terms and conditions',
                        split: 'and',
                        privacy: 'Privacy policy',
                    },
                },
                payBadge: {
                    payWith: 'Pay with',
                },
            },
            checkout: {
                gratefulness: 'Thanks for your purchase!',
                order: 'Order number',
                emailConfirmation: 'You will receive a purchase confirmation to your email',
                goToCourse: 'Go to the course',
                goLogin: 'Create Account',
                generalError: 'Could not complete the purchase',
            },
        },
    },
    alerts: {
        rate: {
            create: {
                success: 'The rate has been published',
                failure: 'There was a problem publishing the rate, please try again',
            },
            edit: {
                success: 'The rate has been edited',
                failure: 'There was a problem editing the rate, please try again',
            },
            delete: {
                success: 'The rate has been deleted',
                failure: 'There was a problem deleting the rate, please try again',
            },
        },
        lesson: {
            create: {
                success: 'The lesson has been created',
                failure: 'There was a problem creating the lesson, please try again',
            },
            edit: {
                success: 'The lesson has been edited',
                failure: 'There was a problem editing the lesson, please try again',
            },
            delete: {
                success: 'The lesson has been deleted',
                failure: 'There was a problem deleting the lesson, please try again',
            },
        },
        catalog_item: {
            create: {
                success: 'The item has been created',
                failure: 'There was a problem creating the item, please try again',
            },
            edit: {
                success: 'The item has been edited',
                failure: 'There was a problem editing the item, please try again',
            },
            delete: {
                success: 'The item has been deleted',
                failure: 'There was a problem deleting the item, please try again',
            },
        },
        evaluation: {
            create: {
                success: 'The exam has been created',
                failure: 'There was a problem creating the exam, please try again',
            },
            edit: {
                success: 'The exam has been edited',
                failure: 'There was a problem editing the exam, please try again',
            },
            delete: {
                success: 'The exam has been deleted',
                failure: 'There was a problem deleting the exam, please try again',
            },
        },
        room: {
            muted: {
                success: 'The conversation has been muted',
                failure: 'There was a problem muting the room, please try again',
            },
            unmuted: {
                success: 'The conversation has been unmuted',
                failure: 'There was a problem unmuting the room, please try again',
            },
        },
        certificate: {
            create: {
                success: 'The certificate has been created',
                failure: 'There was a problem posting the certificate, please try again',
            },
            edit: {
                success: 'The certificate has been edited',
                failure: 'There was a problem editing the certificate, please try again',
            },
            delete: {
                success: 'The certificate has been deleted',
                failure: 'There was a problem deleting the certificate, please try again',
            },
        },
        comment: {
            create: {
                success: 'The comment has been posted',
                failure: 'There was a problem posting the comment, please try again',
            },
            edit: {
                success: 'The comment has been edited',
                failure: 'There was a problem editing the comment, please try again',
            },
            delete: {
                success: 'The comment has been deleted',
                failure: 'There was a problem deleting the comment, please try again',
            },
        },
        event: {
            create: {
                success: 'The event has been created',
                failure: 'There was a problem creating the event, please try again',
            },
            edit: {
                success: 'The event has been edited',
                failure: 'There was a problem editing the event, please try again',
            },
            delete: {
                success: 'The event has been deleted',
                failure: 'There was a problem deleting the event, please try again',
            },
        },
        board: {
            discussion: {
                create: {
                    success: 'The space has been created',
                    failure: 'There was a problem creating the space, please try again',
                },
                edit: {
                    success: 'The space has been edited',
                    failure: 'There was a problem editing the space, please try again',
                },
                delete: {
                    success: 'The space has been deleted',
                    failure: 'There was a problem deleting the space, please try again',
                },
            },
            catalog: {
                create: {
                    success: 'The catalog has been created',
                    failure: 'There was a problem creating the catalog, please try again',
                },
                edit: {
                    success: 'The catalog has been edited',
                    failure: 'There was a problem editing the catalog, please try again',
                },
                delete: {
                    success: 'The catalog has been deleted',
                    failure: 'There was a problem deleting the catalog, please try again',
                },
            },
            course: {
                create: {
                    success: 'The course has been created',
                    failure: 'There was a problem creating the course, please try again',
                },
                edit: {
                    success: 'The course has been edited',
                    failure: 'There was a problem editing the course, please try again',
                },
                delete: {
                    success: 'The course has been deleted',
                    failure: 'There was a problem deleting the course, please try again',
                },
            },
            forum: {
                create: {
                    success: 'The talk has been created',
                    failure: 'There was a problem creating the talk, please try again',
                },
                edit: {
                    success: 'The talk has been edited',
                    failure: 'There was a problem editing the talk, please try again',
                },
                delete: {
                    success: 'The talk has been deleted',
                    failure: 'There was a problem deleting the talk, please try again',
                },
            },
            story: {
                create: {
                    success: 'The story has been created',
                    failure: 'There was a problem creating the story, please try again',
                },
                edit: {
                    success: 'The story has been edited',
                    failure: 'There was a problem editing the story, please try again',
                },
                delete: {
                    success: 'The story has been deleted',
                    failure: 'There was a problem deleting the story, please try again',
                },
            },
        },
        invitation: {
            create: {
                success: 'The invitation has been sent',
                failure: 'There was a problem sending the invitation, please try again',
            },
            edit: {
                success: 'The invitation has been edited',
                failure: 'There was a problem editing the invitation, please try again',
            },
            resend: {
                success: 'The invitation has been resent',
                failure: 'There was a problem resending the invitation, please try again',
                ['application.community.invitation.resend']: 'It is not possible to resend an invitation within less than 24 hours. Please try again after that period.',
            },
            cancel: {
                success: 'The invitation has been canceled',
                failure: 'There was a problem canceling the invitation, please try again',
            },
            delete: {
                success: 'The invitation has been deleted',
                failure: 'There was a problem deleting the invitation, please try again',
            },
        },
        team: {
            create: {
                success: 'The team has been created',
                failure: 'There was a problem creating the team, please try again',
            },
            edit: {
                success: 'The team has been edited',
                failure: 'There was a problem editing the team, please try again',
            },
            delete: {
                success: 'The team has been deleted',
                failure: 'There was a problem deleting the team, please try again',
            },
        },
        team_member: {
            create: {
                success: 'You have successfully added the member to the team.',
                failure: 'The member could not be added to the team.',
            },
            delete: {
                success: 'You have successfully removed this person from the team.',
                failure: 'The person could not be removed from the team.',
            },
        },
        community_member: {
            delete: {
                success: 'You have successfully removed this person.',
                failure: 'The person could not be removed.',
            },
            edit: {
                success: 'You have successfully edited the teams',
                failure: 'The team changes could not be saved',
            },
        },
        post: {
            create: {
                success: 'Your content has been published',
                failure: 'We encountered a problem creating your post. Please try again',
            },
            edit: {
                success: 'Your content has been edited',
                failure: 'We encountered a problem editing your post. Please try again',
            },
            delete: {
                success: 'Your post has been deleted',
                failure: 'We encountered a problem deleting your post. Please try again',
            },
        },
        contact_us: {
            success: 'Message sent!',
            failure: 'We could not send your message. Please try again later',
        },
        community: {
            edit: {
                success: 'You have successfully edited your community!',
                failure: 'An error occurred while editing your community',
            },
        },
        user: {
            edit: {
                success: 'Profile updated successfully',
                failure: 'An error occurred while updating the profile',
            },
        },
    },
    forms: {
        report: 'Your message must contain at least five characters.',
        email: {
            label: 'Email',
            signup_label: 'What is your email?',
            placeholder: 'name@mail.cl',
            validations: {
                required: 'You need to enter your email',
                format: 'Your email is not valid. It should be name@email.c',
            },
        },
        password: {
            label: 'Password',
            signup_label: 'Create your password',
            confirm_label: 'Confirm your password',
            placeholder: '•••••••••',
            prompt: 'Must be at least 8 characters',
            validations: {
                required: 'You need to enter your password',
                format: 'Your password must contain at least one letter and one number',
                short: 'Your password is too short (it must be more than 8 characters)',
                long: 'Your password is too long (can not be more than 20 characters)',
            },
        },
        name: {
            label: 'Name and last name',
            signup_label: 'What is your first and last name?',
            placeholder: 'John Doe',
            validations: {
                required: 'The name cannot be empty',
                format: 'A blank space is missing between name and last name',
                pattern: 'You can only include letters, hyphen, dots and apostrophe.',
                short: 'The name is very short, should be at least 3 letters long (i.e. Ian Z)',
                long: 'The name is is very long, should be maximum 50 letters long',
            },
        },
    },
    imageUpload: {
        banner: {
            actions: {
                cancel: 'Cancel',
                accept: 'Save',
            },
            validations: {
                type: 'You can only upload JPG/PNG file!',
            },
        },
        avatar: {
            actions: {
                cancel: 'Cancel',
                accept: 'Save',
            },
            validations: {
                type: 'You can only upload JPG/PNG file!',
            },
        },
    },
    certificate: {
        preamblePerson: 'Certifies that',
        preambleCourse: 'participated and completed the course',
        preambleTeacher: 'instructed by',
        preambleDate: 'granted',
        preambleUrl: 'Verify this certificate on',
    },
    system: {
        api_error: {
            title: 'We had a problem ',
            description: 'We are experiencing a problem with our site and are troubleshooting it. Please give us some time and try again.',
            action: 'Refresh page',
        },
        access_error: {
            title: 'Content not available',
            description: 'This content is restricted access.',
            action: 'Go to home',
        },
        not_found: {
            title: 'Content not found',
            description: 'It is not possible to display the content.',
            action: 'Back',
        },
    },
};
export default strings;
